import {useCallback, useState} from "react";
import {FilterGroups, FiltersList} from "../../domain/Filters";
import useFiltersContext from "../contexts/use-filters-context";

const useSelectableFilters = () => {
    const {filters: appliedFilters} = useFiltersContext()
    const [selectedFilters, setSelectedFilters] = useState<FiltersList>(appliedFilters)

    const addFilter = useCallback((group: FilterGroups, filter: string) => {
        setSelectedFilters((current: FiltersList) => {
            if (current[group].includes(filter)) return current;

            const filterGroup = [...current[group], filter]

            return {...current, [group]: filterGroup}
        })
    }, [setSelectedFilters])

    const removeFilter = useCallback((group: FilterGroups, filter: string) => {
        setSelectedFilters((current: FiltersList) => {
            if (!current[group].includes(filter)) return current;

            const filterGroup = current[group].filter((g) => g !== filter)

            return {...current, [group]: filterGroup}
        })
    }, [setSelectedFilters])

    const selectAlreadyApplied = useCallback(() => {
        setSelectedFilters(appliedFilters);
    }, [setSelectedFilters, appliedFilters])

    const resolveSelectableFilterChange = useCallback((group: FilterGroups, filter: string, checked: boolean) => {
        if (checked) {
            addFilter(group, filter)

            return;
        }

        removeFilter(group, filter)
    }, [addFilter, removeFilter])

    return {
        selectedFilters,
        addFilter,
        removeFilter,
        selectAlreadyApplied,
        resolveSelectableFilterChange
    }
}

export default useSelectableFilters