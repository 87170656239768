import {useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiClient from "../client/ApiClient";
import {COMITIUM_URL_KEY} from "../domain/Constants";

const useAuthentication = () => {
    const navigate = useNavigate()
    const client = useMemo(() => new ApiClient(), [])
    const [error, setError] = useState<string>("")
    const [isAuthenticating, setAuthenticating] = useState<boolean>(false)

    const authenticate = useCallback(async (token: string) => {
        try {
            localStorage.setItem(COMITIUM_URL_KEY, document.referrer)

            await client.login(token)
        } catch (exception) {
            navigate("/unauthorized")
        }

        navigate("/")
    }, [navigate, client])

    const login = useCallback(async (publicKey: string, secretKey: string): Promise<void> => {
        setError("")
        setAuthenticating(true)

        const token = await client.getToken(publicKey, secretKey)

        if (!token) {
            setError("invalid credentials")
            setAuthenticating(false)

            return;
        }

        try {
            await client.login(token)

            navigate("/")
        } catch (exception) {
            setError("authentication error")
            setAuthenticating(false)
        }
    }, [navigate, client, setError, setAuthenticating])

    const logout = useCallback(() => {

        const urlBackToComitium = localStorage.getItem(COMITIUM_URL_KEY)
        client.logout()

        if(urlBackToComitium){
            window.location.replace(urlBackToComitium)
        }else{
            navigate("/login")
        }

    }, [navigate, client])

    return {
        authenticate,
        login,
        logout,
        error,
        isAuthenticating
    }
}

export default useAuthentication