import React, {memo} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    type: string
}

const ContentType = memo(({type}: Props) => {
    const {t} = useTranslation();

    return <span className="cc-content-list__info--article">
        {t(type)}
    </span>
});

export default ContentType