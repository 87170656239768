import ContentSkeleton from "../Common/Loading/ContentSkeleton";
import {
    Content as DomainContent,
    CONTENTS_LIMIT,
    CONTENTS_PAGINATE,
    TypologyContent as DomainTypologyContent
} from "../../domain/Content";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import useFiltersContext from "../../hooks/contexts/use-filters-context";

interface Props {
    data: DomainContent[]|DomainTypologyContent[]|null
    enableAnimations: (enabled: boolean) => void
}

const MoreContents = ({data, enableAnimations}: Props) => {
    const {t} = useTranslation();
    const {limit, setLimit} = useFiltersContext()
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const loadMore = useCallback(() => {
        enableAnimations(false);
        setLimit((current) => current + CONTENTS_PAGINATE)
        setShowLoading(true);
    }, [setLimit, setShowLoading, enableAnimations])

    const emptyData = useMemo(() => {
        return !data;
    }, [data])

    const showLoadMore = useMemo(() => {
        return data?.length === limit
    }, [data, limit])

    useEffect(() => {
        setShowLoading(false)
        setTimeout(() => enableAnimations(true), 100)
    }, [data, setShowLoading])

    useEffect(() => {
        enableAnimations(false);
    }, [enableAnimations])

    return <>
        { (emptyData || showLoading) && <ContentSkeleton quantity={emptyData ? CONTENTS_LIMIT : CONTENTS_PAGINATE}/> }
        { showLoadMore &&
        <div className="cc-content-more">
            <div
                className={"mc-button"}
                onClick={loadMore}
            >
                {t('view more')}
            </div>
        </div>}
    </>
}

export default MoreContents