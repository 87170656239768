import IconGeneric from "../../Icon/IconGeneric";
import React, {memo} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    date: string
    icon?: boolean
}

const PublishDate = memo(({date, icon = false}: Props) => {
    const {t} = useTranslation();

    return <span className="cc-content-list__info--publish-date" title={t("publish date")}>
        {icon &&
            <IconGeneric iconName="publish-date-ico" className="mc-icon__generic--size-16"/>
        }
        {date}
    </span>
})

export default PublishDate;