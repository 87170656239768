import React from "react";
import {useTranslation} from "react-i18next";
import {FilterGroups} from "../../domain/Filters";

interface Props {
    filterGroups: FilterGroups[]
    onClick: (filterGroup: FilterGroups) => void
}

const FilterGroupOption = ({filterGroups, onClick}: Props) => {
    const {t} = useTranslation()

    return <div className='cc-filter__list'>
        <ul className="cc-filter__group cc-filter__group--preset">
            { filterGroups.map((group) => {
                return <li
                    key={group}
                    className="cc-filter__group-item cc-filter__group-item--preset"
                    onClick={() => onClick(group)}
                >
                    {t(group)}
                </li>
            })}
        </ul>
    </div>
}

export default FilterGroupOption