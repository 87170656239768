import React, {memo, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import IconGeneric from "../../Icon/IconGeneric";

interface Props {
    children: ReactNode
    icon?: boolean
}

const Author = memo(({children, icon = false}: Props) => {
    const {t} = useTranslation();

    return <span className="cc-content-list__info--author" title={t("author")}>
        { icon &&
            <IconGeneric iconName="face-ico" className="mc-icon__generic--size-12" />
        }
        {children}
    </span>
});

export default Author;