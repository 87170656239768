import Sidebar from "./Sidebar";
import {Content} from "../domain/Content";
import ContentDetail from "./Contents/ContentDetail";
import Loading from "./Common/Loading/Loading";
import React, {useEffect, useState} from "react";
import useContentsContext from "../hooks/contexts/use-contents-context";
import DetailFilters from "./Contents/DetailFilters";
import {useParams} from "react-router-dom";
import useContents from "../hooks/use-contents";
import useScrollOverflow from "../hooks/filters/use-scroll-overflow";

const ContentView = () => {
    const {analyticsData} = useContentsContext();
    const {getContent, content: searchedContent} = useContents();
    const {contentId} = useParams();
    const [contentState, setContentState] = useState<Content|null>(null);
    const {isOverflow, ref} = useScrollOverflow();

    useEffect(() => {
        if (contentId) {
            getContent(parseInt(contentId))
        }
    }, [getContent, contentId])

    useEffect(() => {
        if (analyticsData.contents && analyticsData.contents.length > 0) {
            setContentState(analyticsData.contents[0] as Content)
        } else if (searchedContent) {
            setContentState({...searchedContent, pageViews: 0, users: 0, readingTime: 0, modificationDate: 0, topics: []})
        }
    }, [analyticsData.contents])

    return <>
        <div className='cc-main' ref={ref}>
            <div className='oc-flex oc-flex--no-gap'>
                <DetailFilters back={true} isOverflow={isOverflow} />
                <div className="cc-card cc-card--content cc-card--detail">
                    { contentState
                        ? <ContentDetail content={contentState}/>
                        : <Loading/>
                    }
                </div>
            </div>
        </div>
        <Sidebar stats={analyticsData.stats}/>
    </>
}

export default ContentView