import React, {useMemo} from "react";
import Card from "./Card";
import NumberCounter from "../Common/Counters/NumberCounter";
import {CardData} from "../../domain/CardData";
import Skeleton from "../Common/Loading/Skeleton";

interface Props {
    title: string
    data?: CardData
    isMain?: boolean
}

const NumericCard = ({title, data, isMain = false}: Props) => {
    const comparePercentage = useMemo(() => {
        if (!data || data.compare === 0 || data.compare === undefined) {
            return 0;
        }
        return (((data.value - data.compare) / data.compare) * 100).toFixed()
    }, [data])

    if (!data) {
        return <Card title={title} isMain={isMain}>
            <Skeleton type="card"/>
        </Card>
    }

    return <Card title={title} isMain={isMain}>
        <div className="oc-group oc-group--no-gap">
            <NumberCounter value={data.value} classNamePrefix={"cc-card__"} />
        </div>
        {(data.compare !== undefined && data.compare !== 0) && <span
            className={"cc-card__status" + (data.value !== data.compare ? (data.value > data.compare ? " cc-card__status--positive" : " cc-card__status--negative") : "")}
        >
            {comparePercentage}%
        </span>}
    </Card>
}

export default NumericCard