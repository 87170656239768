import React, {useMemo, useState} from "react";
import {TypologyContent as DomainTypologyContent} from "../../domain/Content";
import {useTranslation} from "react-i18next";
import NumberCounter from "../Common/Counters/NumberCounter";
import TimeCounter from "../Common/Counters/TimeCounter";
import PercentCounter from "../Common/Counters/PercentCounter";
import {Link} from "react-router-dom";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import useContentsContext from "../../hooks/contexts/use-contents-context";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";
import TypologyInfo from "../Common/Content/TypologyInfo";
import IconGeneric from "../Icon/IconGeneric";
import CopyButton from "../Common/Buttons/CopyButton";
import OpenLink from "../Common/Buttons/OpenLink";

interface Props {
    content: DomainTypologyContent
}

const TypologyContent = ({content}: Props) => {
    const {t} = useTranslation()
    const {typology, setTypology, saveState, add} = useFiltersContext()
    const {isRealTime} = useDateFiltersHelper()
    const typologyRoute = useMemo(() => {
        if (typology === "categories") return "category";
        if (typology === "authors") return "author";
        if (typology === "not_found") return null;
        return typology;
    }, [typology])
    const {selectContent} = useContentsContext()
    const [isHovered, setIsHovered] = useState(false);

    if (typology === "not_found") {
        return <div className="cc-content-list__item">
            <div className='cc-content-list__article cc-content-list__article--404'>
                <div className={"cc-content-list__title"}>
                    {content.title}
                    <CopyButton textToCopy={content.title}/>
                    <OpenLink url={content.title} classNameSuffix={"404-main-link"}/>
                </div>
                <div className={"cc-content-list__title cc-content-list__title--404"}>
                    <span className="cc-content-list__title--404-label">
                        {content.referer && content.referer.length > 0 ? t("origin") : t('direct traffic')}
                    </span>
                    {content.referer && content.referer.length > 0 && (<>
                        {content.referer}
                        <CopyButton textToCopy={content.referer}/>
                        <OpenLink url={content.referer} classNameSuffix={"404-link"}/>
                    </>)}
                </div>
            </div>
            <div className='cc-content-list__data cc-content-list__data--404'>
                <div className="cc-content-list__data--pages">
                    <span className="cc-content-list__data--label">{t("pageviews")}</span>
                    <NumberCounter value={content.pageViews} classNamePrefix={"cc-content-list__data--"}/>
                </div>
                <div className='cc-content-list__data--concurrence'>
                    <span className="cc-content-list__data--label">{t(isRealTime ? "active users" : "unique users")}</span>
                    <NumberCounter value={content.users} isMain={isRealTime} classNamePrefix={"cc-content-list__data--"}/>
                </div>
            </div>
        </div>
    }

    return (
        <div className="cc-content-list__item">
            <div className='cc-content-list__article'>
                <div className={"cc-content-list__title"}>
                    <span title={t("apply filter")} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => {
                        if (typologyRoute) {
                            add(typologyRoute, content.title);
                        }
                        setTypology(null);
                    }}>
                        {/* <IconGeneric iconName="filters-ico" className="mc-icon__generic--size-10 mc-icon--button mc-icon--filter"/> */}
                        <IconGeneric 
                            iconName={isHovered ? "filters-filled-primary-ico" : "filters-ico"} 
                            className="mc-icon__generic--size-10 mc-icon--button mc-icon--filter"
                        />
                    </span>
                    <Link
                        to={"/"+typologyRoute+"/"+content.title}
                        onClick={() => {
                            selectContent(content)
                            saveState()
                            setTimeout(() => setTypology(null),100)
                        }}
                    >
                        {content.title}
                    </Link>
                </div>
                <TypologyInfo contents={content.contents} />
            </div>
            <div className='cc-content-list__data'>
                <div className="cc-content-list__data--pages">
                    <span className="cc-content-list__data--label">{t("pageviews")}</span>
                    <NumberCounter value={content.pageViews} classNamePrefix={"cc-content-list__data--"}/>
                </div>
                <div className="cc-content-list__data--time">
                    <span className="cc-content-list__data--label">{t("reading time")}</span>
                    <span className="cc-content-list__data--value">
                        <TimeCounter seconds={content.readingTime} classNamePrefix={"cc-content-list__data--"} />
                    </span>
                </div>
                <div className='cc-content-list__data--concurrence'>
                    <span className="cc-content-list__data--label">{t(isRealTime ? "active users" : "unique users")}</span>
                    <NumberCounter value={content.users} isMain={isRealTime} classNamePrefix={"cc-content-list__data--"}/>
                </div>
            </div>
        </div>
    )
}

export default TypologyContent