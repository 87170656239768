import React, { memo } from "react";
import DomainFavIcon from "../DomainFavIcon";
import Author from "./Author";
import ContentType from "./ContentType";
import PublishDate from "./PublishDate";
import ModificationDate from "./ModificationDate";
import Category from "./Category";

interface Props {
    url: string
    type: string
    category: string
    publishDate?: string | null
    modificationDate?: string | null
    author: string
}

const ContentInfo = memo(({ url, type, category, author, publishDate = null, modificationDate = null }: Props) => {
    return <div className="cc-content-list__info cc-content-list__info--main">
        <DomainFavIcon url={url} />
        <ContentType type={type} />
        {category &&
            <Category category={category} icon={true} />
        }
        {publishDate &&
            <PublishDate date={publishDate} icon={true} />
        }
        {modificationDate &&
            <ModificationDate date={modificationDate} icon={true} />
        }
        {author &&
            <Author icon={true}>
                {author}
            </Author>
        }
    </div>
})

export default ContentInfo