import {useTranslation} from "react-i18next";
import useAiContent from "../../../hooks/use-ai-content";
import {Content} from "../../../domain/Content";
import Loading from "../../Common/Loading/Loading";
import useAiSuggest from "../../../hooks/use-ai-suggest";
import Suggest from "./Suggest";
import IconGeneric from "../../Icon/IconGeneric";
import {useState} from "react";

interface Props {
    content: Content
}

const ContentAI = ({content}: Props) => {
    const {t} = useTranslation();
    const {
        props,
        setTitle,
        setMetaTitle,
        setMetaDescription,
        updateContent,
        isLoading,
        error,
        canSubmit,
    } = useAiContent(content.accountToken, content.url, content.locale);
    const {
        fetchSuggestions,
        suggestions,
        setSuggestionTitle,
        setSuggestionMetaTitle,
        setSuggestionMetaDescription,
    } = useAiSuggest();

    const [begin, setBegin] = useState<boolean>(false)

    if (!begin) {
        return <div id="analytics-ai" className="cc-ai__suggest">
            <div className="cc-ai__title">{t('analytics ai title')}</div>
            <div className="cc-ai__subtitle">{t('analytics ai subtitle')}</div>
            <div className={"mc-button mc-button--rounded mc-button--start-ai"} onClick={async () => {
                setBegin(true)
                await fetchSuggestions(content.url)
            }}>
                {t('start')}
                <IconGeneric iconName="magic-wand-ico" className="mc-icon__generic--size-16 is-primary" />
            </div>
        </div>
    }

    return <>
        <div id="analytics-ai" className="cc-ai__suggest">
            <div className="cc-ai__title">{t('analytics ai title')}</div>
            <div className="cc-ai__subtitle">{t('analytics ai help')}</div>

            <div className="cc-ai__source-title">Google Discover</div>
            <Suggest
                label={t('ai content title')}
                items={suggestions?.title ?? null}
                selected={props.title}
                onSelect={setTitle}
                onEdit={setSuggestionTitle}
            />

            <Suggest
                label={t('ai seo title')}
                items={suggestions?.metadata?.title ?? null}
                selected={props.metadata.title}
                onSelect={setMetaTitle}
                onEdit={setSuggestionMetaTitle}
            />

            <Suggest
                label={t('ai seo description')}
                items={suggestions?.metadata?.description ?? null}
                selected={props.metadata.description}
                onSelect={setMetaDescription}
                onEdit={setSuggestionMetaDescription}
            />

            { error &&
                <div className="cc-ai__source-title">{t('error updating content')}</div>
            }

            <div className={"mc-button mc-button--primary mc-button--rounded mc-button--ai-apply" + (!canSubmit ? " is-disabled" : "")} onClick={updateContent}>
                {isLoading ? <Loading/> : t('apply selected changes')}
            </div>
        </div>
    </>
}

export default ContentAI;