import {useMemo} from "react";
import useFiltersContext from "../contexts/use-filters-context";
import useDates from "../use-dates";
import {formatContentDate} from "../../utils/date"
import {useTranslation} from "react-i18next";

const useDateFiltersHelper = () => {
    const { i18n} = useTranslation()
    const {dateRange} = useFiltersContext();
    const {formatDate} = useDates();

    const isRealTime = useMemo(() => {
        return dateRange.label === 'realtime 5 minutes' || dateRange.label === 'realtime 30 minutes';
    }, [dateRange.label])

    const isRealTimeFiveMinutes = useMemo(() => {
        return dateRange.label === 'realtime 5 minutes';
    }, [dateRange.label])

    const isRealTimeThirtyMinutes = useMemo(() => {
        return dateRange.label === 'realtime 30 minutes';
    }, [dateRange.label])

    const isToday = useMemo(() => {
        return dateRange.label === 'today';
    }, [dateRange.label])

    const isYesterday = useMemo(() => {
        return dateRange.label === 'yesterday';
    }, [dateRange.label])

    const isCustom = useMemo(() => {
        return dateRange.label === 'custom';
    }, [dateRange.label])

    const compareLabel = useMemo((): string => {
        if (["realtime 5 minutes", "realtime 30 minutes", "today", "yesterday", "last 7 days"].includes(dateRange.label)) {
            return "one week ago"
        }

        if (["last 30 days"].includes(dateRange.label)) {
            return "30 days ago"
        }

        if (["current month", "last month"].includes(dateRange.label)) {
            return "last month"
        }

        return ""
    }, [dateRange.label])

    const periodDateLabel = useMemo( () => (startDate: Date | null, endDate: Date | null): string => {
        const start = formatDate(startDate);
        const end = formatDate(endDate)

        return start === end ? start : (start + " - " + end);
    }, [formatDate])

    const customDateLabel = useMemo((): string => {
        return periodDateLabel(dateRange.start, dateRange.end)
    }, [dateRange, formatDate])

    const filterDateLabel = useMemo((): string => {
        if(isCustom){
            return customDateLabel
        }

        const today = new Date();

        if (isToday) {
            return formatContentDate(today, i18n.language)
        }

        const oneDayMs = 24 * 3600 * 1000;
        const yesterday = new Date(today.getTime() - oneDayMs);

        if (isYesterday) {
            return formatContentDate(yesterday, i18n.language)
        }

        if (["last 7 days"].includes(dateRange.label)) {
            const sevenDaysBefore = new Date(today.getTime() - 7 * oneDayMs);
            return periodDateLabel(sevenDaysBefore, yesterday)
        }

        if (["last 30 days"].includes(dateRange.label)) {
            const thirtyDaysBefore = new Date(today.getTime() - 30 * oneDayMs);
            return periodDateLabel(thirtyDaysBefore, yesterday)
        }

        if (["current month"].includes(dateRange.label)) {
            const currentMonth = new Date(today.setMonth(today.getMonth()));
            return currentMonth.toLocaleString(i18n.language, {
                month: 'long',
                year: 'numeric',
            })
        }

        if (["last month"].includes(dateRange.label)) {
            const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
            return lastMonth.toLocaleString(i18n.language, {
                month: 'long',
                year: 'numeric',
            })
        }

        return '--/--/--';

    }, [dateRange.label, formatContentDate, i18n.language, isToday, isYesterday, isCustom, customDateLabel, periodDateLabel])

    return {
        dateRange,
        isRealTime,
        isRealTimeFiveMinutes,
        isRealTimeThirtyMinutes,
        isToday,
        isYesterday,
        isCustom,
        compareLabel,
        customDateLabel,
        filterDateLabel
    }
}

export default useDateFiltersHelper