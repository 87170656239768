import IconGeneric from "../../Icon/IconGeneric";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    category: string
    icon?: boolean
}

const Category = ({category, icon = false}: Props) => {
    const {t} = useTranslation();
    
    return <span className="cc-content-list__info--category" title={t("category")}>
        {icon &&
            <IconGeneric iconName="category-ico" className="mc-icon__generic--size-16" />
        }
        {category}
    </span>
}

export default Category;