import React, {memo} from "react";

interface Props {
    url: string
    size?: number
    alt?: string
}

const DomainFavIcon = memo(({url, alt = "", size = 12}: Props) => {
    return <img
        className={'mc-icon__generic--size-' + size}
        src={"https://www.google.com/s2/favicons?domain="+url+"&sz=32"}
        alt={alt}
    />
});

export default DomainFavIcon;