import classnames from "classnames";
import IconGeneric from "../Icon/IconGeneric";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import FilterGroupSelector from "./FilterGroupSelector";
import FilterGroupOption from "./FilterGroupOption";
import {FilterGroups} from "../../domain/Filters";
import {useParams} from "react-router-dom";
import useOutsideClick from "../../hooks/use-outside-click";
import useContentsContext from "../../hooks/contexts/use-contents-context";

const FilterSelector = () => {
    const {t} = useTranslation();
    const {contentId, category, author} = useParams();
    const {filters: allFilters} = useContentsContext()
    const [filterDropdown, setFilterDropdown] = useState<boolean>(false)
    const filterGroups = useMemo(() => {
        const groups = Object.keys(allFilters) as FilterGroups[]

        if (contentId) return groups.filter((group) => !["category", "author"].includes(group))

        if (category) return groups.filter((group) => group !== "category")

        if (author) return groups.filter((group) => group !== "author")

        return groups;
    }, [allFilters, contentId, category, author])
    const [selectedGroup, setSelectedGroup] = useState<FilterGroups|"">("")
    const ref = useOutsideClick(useCallback(() => {
        setFilterDropdown(false);
    }, [setFilterDropdown]))

    const toggleDropdown = () => {
        setFilterDropdown(!filterDropdown)
    }

    return <>
        <div className='cc-filter__container' ref={ref}>
            <div className={classnames('cc-filter__button cc-filter__button--parameter', {'is-open': filterDropdown})}
                onClick={toggleDropdown}
            >
                <IconGeneric iconName="filters-ico" className="mc-icon__generic--size-16" />
                {t('filters')}
            </div>
            { filterDropdown &&
                <div className="cc-filter__dropdown has-fade-in">
                    <FilterGroupOption
                        filterGroups={filterGroups.map((group) => group)}
                        onClick={(group) => {
                            toggleDropdown()
                            setSelectedGroup(group)
                        }}
                    />
                </div>
            }
        </div>

        { filterGroups.map((group) => {
            return <FilterGroupSelector
                key={group}
                group={group as FilterGroups}
                forceDropdownGroup={selectedGroup}
                onClose={() => setSelectedGroup("")}
            />
        })}
    </>
}

export default FilterSelector