import {useCallback, useState} from "react";
import ApiClient from "../client/ApiClient";
import {SearchContent} from "../domain/Content";

const useContents = () => {
    const [contents, setContents] = useState<SearchContent[]>([])
    const [content, setContent] = useState<SearchContent|null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)

    const search = useCallback(async (term: string, quantity: number) => {
        setLoading(true);
        const client = new ApiClient();

        try {
            const response = await client.request("get", "/contents", {
                search: term,
                quantity
            })

            setContents(response.data)
        } catch (exception) {
            console.log(exception)
        } finally {
            setLoading(false);
        }
    }, [])

    const getContent = useCallback(async (id: number) => {
        setLoading(true);
        const client = new ApiClient();

        try {
            const response = await client.request("get", `/contents/${id}`)

            setContent(response.data)
        } catch (exception) {
            console.log(exception)
        } finally {
            setLoading(false);
        }
    }, [])

    return {
        search,
        contents,
        getContent,
        content,
        isLoading,
    }
}

export default useContents;