import React, {memo} from "react";
import Title from "../Common/Content/Title";
import Summary from "../Summary/Summary";
import useContentsContext from "../../hooks/contexts/use-contents-context";
import MainChart from "../Chart/MainChart";

interface Props {
    typologyTitle: string
}

const TypologyDetail = memo(({typologyTitle}: Props) => {
    const {analyticsData} = useContentsContext();

    return <div className='cc-content'>
        <div className="cc-content__item">
            <div className='cc-content-list__article'>
                <Title>{typologyTitle}</Title>
            </div>
        </div>
        <MainChart chart={analyticsData.chart}/>
        <Summary data={analyticsData.summary}/>
    </div>
})

export default TypologyDetail