import IconGeneric from "../Icon/IconGeneric";
import DateSelector from "../DateFilter/DateSelector";
import FilterSelector from "../FilterDropdown/FilterSelector";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";

interface Props {
    back?: boolean
    isOverflow?: boolean
}

const DetailFilters = ({back = false, isOverflow = false}: Props) => {
    const {restoreState, filtersList, reset: resetFilters} = useFiltersContext();
    const navigate = useNavigate();
    const [isCollapse, setIsCollapse] = useState(false);
    const toggleCollapse = () => {setIsCollapse(!isCollapse);};

    return <div className={`cc-filter${isOverflow ? ' is-overflow' : ''}`}>
        { back &&
            <div className={"mc-button mc-button--rounded mc-button--back"} onClick={() => {
                restoreState()
                navigate("/")
            }}>
                <IconGeneric iconName="arrow-back-ico" className="mc-icon__generic--size-20 is-primary" />
            </div>
        }
        <button className={"mc-button mc-button--small mc-button--toggle-filters" + (!isCollapse ? "" : " is-open")} onClick={toggleCollapse}>
            {/* <IconGeneric iconName="filters-filled-primary-ico" className="mc-icon__generic--size-12 is-primary" /> */}
            <IconGeneric 
                iconName={isCollapse ? "close-ico" : "filters-filled-primary-ico"} 
                className="mc-icon__generic--size-12 is-primary" 
            />
        </button>
        <div className={"cc-filter__wrapper" + (isOverflow ? " is-overflow" : "") + (!isCollapse ? "" : " is-open")}>
            <DateSelector />
            <FilterSelector />
            { filtersList.length > 0 &&
                <button className='mc-button mc-button--small mc-button--delete-filters' onClick={() => {
                    resetFilters()
                }}>
                    <IconGeneric iconName="delete-ico" className="mc-icon__generic--size-20 is-primary" />
                </button>
            }
        </div>
    </div>
}

export default DetailFilters