import ContentsTitle from "./ContentsTitle";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";
import {useTranslation} from "react-i18next";

const ContentsHeader = () => {
    const {t} = useTranslation();
    const {dateRange, isRealTime, isRealTimeThirtyMinutes, isCustom, customDateLabel} = useDateFiltersHelper();

    return <div className="cc-content-header">
        {isRealTime ?
            <ContentsTitle title={isRealTimeThirtyMinutes ? t("last 30 minutes") : t("last 5 minutes")} />:
            <ContentsTitle title={isCustom ? customDateLabel : t(dateRange.label)} />
        }
    </div>
}

export default ContentsHeader