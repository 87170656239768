import Content from "./Content";
import {
    Content as DomainContent,
    TypologyContent as DomainTypologyContent
} from "../../domain/Content";
import { useTranslation } from "react-i18next";
import ContentTypesFilters from "./ContentTypesFilters";
import ContentTypologies from "./ContentTypologies";
import TypologyContent from "./TypologyContent";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ContentsHeader from "./ContentsHeader";
import MoreContents from "./MoreContents";
import ContentsColumnsHeader from "./ContentsColumnsHeader";

interface Props {
    data: DomainContent[] | DomainTypologyContent[] | null
}

const Contents = ({ data }: Props) => {
    const { t } = useTranslation();
    const [parent, enableAnimations] = useAutoAnimate();

    return (
        <>
            <ContentsHeader />
            <div className="cc-content-nav">
                <ContentTypologies />
                <ContentTypesFilters />
            </div>
            <div className="cc-content-list">
                <ContentsColumnsHeader />
                <div className="cc-content-list" ref={parent}>
                    {data?.length === 0 &&
                        <div className="cc-content-list__no-results">
                            <div className="cc-content-list__no-results--omg">(≥o≤)</div>
                            <div className="cc-content-list__no-results--title">{t("no results")}</div>
                            <div className="cc-content-list__no-results--subtitle">{t("try to modify the active filters to get results")}</div>
                        </div>
                    }
                    {data?.map((content) => {
                        if ('url' in content) {
                            return <Content key={content.url + content.title} content={content} />
                        }

                        return <TypologyContent key={content.title} content={content} />
                    })}
                    <MoreContents data={data} enableAnimations={enableAnimations} />
                </div>
            </div>
        </>
    )
}

export default Contents