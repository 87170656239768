import {useMemo} from "react";
import {addThousandsSeparator} from "../utils/number";

interface ResolvedNumber {
    resolvedValue: string
    unit: string|null
}

const useNumberUnitResolver = (value: number): ResolvedNumber => {
    const unit: "k"|"M"|null = useMemo(() => {
        if (value > 999999) {
            return "M"
        }

        return value > 99999 ? "k" : null
    }, [value])

    const resolvedValue = useMemo(() => {
        if (unit === "k") {
            return (value / 1000).toFixed(0);
        }

        if (unit === "M") {
            return (value / 1000000).toFixed(2).replace(".", ",")
        }

        return addThousandsSeparator(value);
    }, [value, unit])

    return {
        resolvedValue,
        unit
    }
}

export default useNumberUnitResolver