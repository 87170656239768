import {useTranslation} from "react-i18next";
import {ContentTypologies as DomainContentTypologies} from "../../domain/Content";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import NavItem from "../Common/Buttons/NavItem";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

const contentTypologies: DomainContentTypologies[] = [
    "categories",
    "authors",
];

const ContentTypologies = () => {
    const {t} = useTranslation()
    const {filters, typology, setTypology, removeGroup} = useFiltersContext()
    const {category, author, topic} = useParams();

    const onClick = useCallback((contentType: DomainContentTypologies) => {
        setTypology(contentType)

        if (filters.contentType.length > 0) {
            removeGroup('contentType')
        }
    }, [filters.contentType, setTypology, removeGroup])
    
    return (
        <div className="cc-content-nav__list cc-content-nav__list--subitems">
            <div className="cc-chart-bar__title">{t('dimension')}</div>
            <div className="cc-content-nav__items">
                <NavItem isActive={typology === null} onClick={() => {
                    setTypology(null)
                }}>
                    {t('all')}
                </NavItem>

                { contentTypologies.map((contentType) => {
                    if ((contentType === 'authors' && author) ||
                        (contentType === 'categories' && category)) {

                        return <></>;
                    }

                    return <NavItem key={contentType} isActive={typology === contentType} onClick={() => {
                        onClick(contentType);
                    }}>
                        {t(contentType)}
                    </NavItem>
                }) }


                {!(topic) && <NavItem isActive={typology === "topics"} onClick={() => {
                    onClick("topics");
                }}>
                    {t('topics')}
                </NavItem>}
            </div>
        </div>
    )
}

export default ContentTypologies