import Sidebar from "./Sidebar";
import TypologyDetail from "./Contents/TypologyDetail";
import React from "react";
import {useParams} from "react-router-dom";
import useContentsContext from "../hooks/contexts/use-contents-context";
import DetailFilters from "./Contents/DetailFilters";
import TypologyContents from "./Contents/TypologyContents";
import useScrollOverflow from "../hooks/filters/use-scroll-overflow";

const TypologyView = () => {
    const {analyticsData} = useContentsContext();
    const {topic, category, author} = useParams();
    const {isOverflow, ref} = useScrollOverflow();

    return <>
        <div className='cc-main cc-main--typology' ref={ref}>
            <div className='oc-flex oc-flex--no-gap'>
                <DetailFilters back={true} isOverflow={isOverflow}/>
                <div className="cc-card cc-card--content cc-card--detail">
                    <TypologyDetail typologyTitle={topic ?? category ?? author ?? ''}/>
                    <div className='cc-content cc-content--typology'>
                        <div className='cc-card cc-card--content cc-card--typology'>
                            <TypologyContents data={analyticsData.contents} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Sidebar stats={analyticsData.stats}/>
    </>
}

export default TypologyView