import React, {useMemo} from "react";
import useNumberUnitResolver from "../../hooks/use-number-unit-resolver";

interface Props {
    label: string
    value: string
    color: string
    total: number
}

const ChartTooltipBasicItem = ({label, value, color, total}: Props) => {
    const val = useMemo(() => parseInt(value), [value])

    const {resolvedValue, unit} = useNumberUnitResolver(val)

    const percent = val / total * 100;

    return (
        <div className="cc-chart__tooltip-wrapper">
            <div className="cc-chart__tooltip-item" style={{color: color}}>
                <span className="cc-chart__tooltip-item-name">{label}</span>
                <div className="cc-chart__tooltip-item-bar">
                    <div className="cc-chart__tooltip-item-bar--new" style={{width: Number.isFinite(percent) ? percent+'%' : '0%', backgroundColor: color}}></div>
                </div>
                <div className="cc-chart__tooltip-item-value">
                    <div className="cc-chart__tooltip-item-value--new">{resolvedValue}{unit}</div>
                </div>
            </div>
        </div>
    )
}

export default ChartTooltipBasicItem