import React, {memo, ReactNode} from "react";

interface Props {
    children: ReactNode
}

const Title = memo(({children}: Props) =>
    <span className="cc-content-list__title">
        {children}
    </span>
)

export default Title