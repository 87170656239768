import React from "react";

interface Props {
    title: string
    subtitle: string
}

const GroupCardTitle = ({title, subtitle}: Props) => {
    return (
        <div className="oc-group oc-group--half-gap oc-sticky oc-sticky__left--0">
            <h2 className="cc-summary__title">{title}</h2>
            <h3 className="cc-summary__subtitle">{subtitle}</h3>
        </div>
    )
}

export default GroupCardTitle