import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppliedFilters from "./AppliedFilters";
import useSelectableFilters from "../../hooks/filters/use-selectable-filters";
import Option from "./Option";
import { FilterGroups } from "../../domain/Filters";
import useOutsideClick from "../../hooks/use-outside-click";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import useContentsContext from "../../hooks/contexts/use-contents-context";

interface Props {
    group: FilterGroups
    forceDropdownGroup: FilterGroups | ""
    onClose: () => void
}

const FilterGroupSelector = ({ group, forceDropdownGroup, onClose }: Props) => {
    const { t } = useTranslation();
    const { filters: allFilters } = useContentsContext()
    const { setFilters, filters: appliedFilters } = useFiltersContext()
    const { selectedFilters, resolveSelectableFilterChange, selectAlreadyApplied } = useSelectableFilters()
    const [filterDropdown, setFilterDropdown] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")
    const forceDropdown = useMemo(() => forceDropdownGroup === group, [forceDropdownGroup, group])

    const toggleDropdown = useCallback((show: boolean) => {
        selectAlreadyApplied()
        setSearch("")
        setFilterDropdown(show)
    }, [selectAlreadyApplied, setSearch, setFilterDropdown]);

    const apply = () => {
        setFilters(selectedFilters)
        toggleDropdown(false)
    }

    useEffect(() => {
        toggleDropdown(forceDropdown)
    }, [forceDropdown, toggleDropdown])

    const ref = useOutsideClick(useCallback(() => {
        if (forceDropdown) {
            onClose()
        }

        toggleDropdown(false)
    }, [forceDropdown, toggleDropdown, onClose]))

    if (forceDropdownGroup !== group && appliedFilters[group].length === 0) {
        return null;
    }

    return <div className='cc-filter__container' ref={ref}>
        <div className={classnames('cc-filter__button cc-filter__button--parameter cc-filter__button--selected', { 'is-open': filterDropdown })}
            onClick={() => {
                toggleDropdown(!filterDropdown)
                onClose()
            }}
        >
            <div className="cc-filter__label">{t(group)}</div>
            <AppliedFilters group={group} />
        </div>
        {(filterDropdown || forceDropdown) &&
            <div className="cc-filter__dropdown has-fade-in">
                <input
                    className='cc-form__input cc-form__input--search'
                    type="search"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />

                <div className='cc-filter__group'>
                    {allFilters[group]
                        .filter((filter) => filter.toLowerCase().includes(search.toLowerCase()))
                        .map((filter) => {
                            return <Option
                                key={filter}
                                defaultChecked={appliedFilters[group].includes(filter)}
                                onChange={(value) => resolveSelectableFilterChange(group, filter, value)}
                            >
                                {filter}
                            </Option>
                        })
                    }
                </div>

                <div className='oc-group oc-group--center'>
                    <button className='mc-button mc-button--secondary mc-button--small' onClick={() => {
                        toggleDropdown(false)
                        onClose()
                    }}>
                        {t("cancel")}
                    </button>
                    <button className='mc-button mc-button--primary mc-button--small' onClick={() => {
                        apply()
                        onClose()
                    }}>
                        {t("apply")}
                    </button>
                </div>
            </div>
        }
    </div>
}

export default FilterGroupSelector