import Summary from "./Summary/Summary";
import MainChart from "./Chart/MainChart";
import Contents from "./Contents/Contents";
import Sidebar from "./Sidebar";
import React from "react";
import useContentsContext from "../hooks/contexts/use-contents-context";
import DetailFilters from "./Contents/DetailFilters";
import useScrollOverflow from "../hooks/filters/use-scroll-overflow";

const DashboardView = () => {
    const {analyticsData} = useContentsContext();
    const {isOverflow, ref} = useScrollOverflow();

    return <>
        <div className='cc-main' ref={ref}>
            <div className='oc-flex oc-flex--no-gap'>
                <DetailFilters isOverflow={isOverflow} />
                <Summary data={analyticsData.summary}/>
                <MainChart chart={analyticsData.chart}/>
            </div>
            <div className='cc-content'>
                <div className='cc-card cc-card--content'>
                    <Contents data={analyticsData.contents}/>
                </div>
            </div>
        </div>
        <Sidebar stats={analyticsData.stats}/>
    </>
}

export default DashboardView