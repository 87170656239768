import IconGeneric from "./Icon/IconGeneric";
import FiltersBarList from "./FilterBar/FiltersBarList";
import OrderedFiltersBarList from "./FilterBar/OrderedFiltersBarList";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {StatsData} from "../hooks/use-analytics-data";
import useContentsContext from "../hooks/contexts/use-contents-context";
import useFiltersContext from "../hooks/contexts/use-filters-context";

interface Props {
    stats: StatsData
}

const Sidebar = ({stats}: Props) => {
    const {t} = useTranslation()
    const [isCollapse, setIsCollapse] = useState(false);
    const toggleCollapse = () => {setIsCollapse(!isCollapse);};
    const {
        accumulatedSourceStats,
        accumulatedCountryStats,
        accumulatedLanguageStats,
        accumulatedCampaignStats
    } = useContentsContext();
    const {filters} = useFiltersContext()

    return <>
        <div className={"cc-sidebar__collapse" + (!isCollapse ? "" : " is-hidden")} onClick={toggleCollapse}>
            <span className="cc-sidebar__collapse--button">
                <IconGeneric iconName="arrow-right-ico" className="mc-icon__generic--size-24" />
            </span>
        </div>
        <div className={"cc-sidebar" + (!isCollapse ? "" : " is-hidden")}>
            <FiltersBarList
                title={t('traffic')}
                data={stats.traffic}
                className="cc-chart-bar__channel"
                group={"traffic"}
            />
            <OrderedFiltersBarList
                title={t('source')}
                data={accumulatedSourceStats}
                className="cc-chart-bar__source"
                group={"source"}
                percentage={false}
            />
            <OrderedFiltersBarList
                title={t('device')}
                data={stats.device}
                className="cc-chart-bar__device"
                group={"device"}
            />
            <OrderedFiltersBarList
                title={t('platform')}
                data={stats.platform}
                className="cc-chart-bar__platform"
                group={"platform"}
            />
            { (filters.language?.length > 0 || accumulatedLanguageStats?.length !== 1) && <OrderedFiltersBarList
                title={t('language')}
                data={accumulatedLanguageStats}
                className="cc-chart-bar__language"
                group={"language"}
            /> }
            <OrderedFiltersBarList
                title={t('temporality')}
                data={stats.temporality}
                className="cc-chart-bar__temporality"
                group={"temporality"}
            />
            <OrderedFiltersBarList
                title={t('user')}
                data={stats.user}
                className="cc-chart-bar__user"
                group={"user"}
            />
            <OrderedFiltersBarList
                title={t('country')}
                data={accumulatedCountryStats}
                className="cc-chart-bar__country"
                group={"country"}
                percentage={false}
            />
            {(accumulatedCampaignStats && accumulatedCampaignStats.length > 0) && <OrderedFiltersBarList
                title={t('campaign')}
                data={accumulatedCampaignStats}
                className="cc-chart-bar__campaign"
                group={"campaign"}
                percentage={false}
            />}
        </div>
    </>
}

export default Sidebar