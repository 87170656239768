import Header from "../components/Header/Header";
import React from "react";
import {useRouteError} from "react-router-dom";

const Error = () => {
    const error = useRouteError() as any;

    return (
        <div className="comitium-analytics">
            <Header />
            {"ERROR 404 - " + (error.statusText || error.message)}
        </div>
    );
}

export default Error;