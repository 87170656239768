import React, {useContext, useState} from "react";
import Logo from "../Icon/Logo";
import { useTranslation } from "react-i18next";
import HeaderItem from "./HeaderItem";
import { AppContext } from "../../pages/Home";
import Loading from "../Common/Loading/Loading";
import HeaderAccounts from "./HeaderAccounts";
import LangSelector from "./LangSelector";
import useAuthentication from "../../hooks/use-authentication";
import IconGeneric from "../Icon/IconGeneric";
import Searcher from "../Searcher/Searcher";

const Header = () => {
    const { t } = useTranslation();
    const { accounts, selectedAccount, setSelectedAccount } = useContext(AppContext);
    const { logout } = useAuthentication();
    const [isCollapse, setIsCollapse] = useState(false);
    const toggleCollapse = () => {setIsCollapse(!isCollapse);};

    // const [isNav, setIsNav] = useState(false);
    // const toggleNav = () => {setIsNav(!isNav);};

    return (
        <header className="cc-header">
            {/* <HeaderItem className={'cc-header__menu' + (!isNav ? "" : " is-open-nav")} onClick={toggleNav}>
                <button className={"mc-button mc-button--secondary"}>
                    <IconGeneric iconName="menu-ico" className="mc-icon__generic--size-24" />
                </button>
            </HeaderItem> */}
            <Logo/>
            <div className={"cc-header__sites" + (!isCollapse ? "" : " is-open")}>
                { accounts.length > 1 && <HeaderItem className={(!selectedAccount ? "is-active" : "")}>
                    <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header"
                        onClick={() => {
                            setSelectedAccount(null);
                            setIsCollapse(false);
                        }}
                    >
                        {t("all sites")}
                    </button>
                </HeaderItem> }
                <HeaderAccounts onClick={() => setIsCollapse(false)} />
                { accounts.length === 0 &&
                   <Loading/>
                }

                {accounts.length > 1 && 
                    <div className={"cc-header__dropdown-arrow cc-header__dropdown-arrow--overflowed" + (!isCollapse ? "" : " is-open")} onClick={toggleCollapse}>
                        <span>···</span>
                    </div>
                }

            </div>
            <Searcher />
            <LangSelector />
            <button className='mc-button mc-button--secondary mc-button--no-border mc-button--logout' onClick={() => {
                logout()
            }}>
                <IconGeneric iconName="logout-ico" className="mc-icon__generic--size-24" />
            </button>
        </header>
    );
}

export default Header;