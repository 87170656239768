import {FilterGroups, FiltersList} from "../../domain/Filters";
import {createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState} from "react";
import {DateRange} from "../../domain/DateRange";
import {CONTENTS_LIMIT, ContentTypologies} from "../../domain/Content";

export interface FiltersContextInterface {
    filters: FiltersList
    setFilters: Dispatch<SetStateAction<FiltersList>>
    add: (group: FilterGroups, filter: string) => void
    remove: (group: FilterGroups, filter: string) => void
    removeGroup: (group: FilterGroups) => void
    reset: () => void
    saveState: () => void
    restoreState: () => void
    filtersList: string[]
    dateRange: DateRange
    setDateRange: Dispatch<SetStateAction<DateRange>>
    limit: number
    setLimit: Dispatch<SetStateAction<number>>
    typology: ContentTypologies|null,
    setTypology: Dispatch<SetStateAction<ContentTypologies|null>>
}

export const FiltersContext = createContext<FiltersContextInterface>({} as FiltersContextInterface)

const useFiltersContext = () => useContext(FiltersContext)

export const useFiltersContextData = (): FiltersContextInterface => {
    const [appliedFilters, setAppliedFilters] = useState<FiltersList>(initialFilters)
    const [savedFiltersState, setSavedFiltersState] = useState<FiltersList>(initialFilters)
    const [dateRange, setDateRange] = useState<DateRange>({
        label: 'realtime 5 minutes',
        start: null,
        end: null
    })
    const [limit, setLimit] = useState<number>(CONTENTS_LIMIT)
    const [appliedTypology, setAppliedTypology] = useState<ContentTypologies|null>(null)
    const [savedTypologyState, setSavedTypologyState] = useState<ContentTypologies|null>(null)

    const resetAppliedFilters = useCallback(() => {
        setAppliedFilters(initialFilters);
    }, [setAppliedFilters]);

    return {
        filters: appliedFilters,
        setFilters: setAppliedFilters,
        add: (group: FilterGroups, filter: string) => {
            setAppliedFilters((current: FiltersList) => {
                if (current[group].includes(filter)) return current;

                return {...current, [group]: [...current[group], filter]}
            })
        },
        remove: (group: FilterGroups, filter: string) => {
            setAppliedFilters((current: FiltersList) => {
                return {...current, [group]: current[group].filter((f) => f !== filter)}
            })
        },
        removeGroup: useCallback((group: FilterGroups) => {
            setAppliedFilters((current: FiltersList) => {
                return {...current, [group]: []}
            })
        }, [setAppliedFilters]),
        reset: resetAppliedFilters,
        saveState: useCallback(() => {
            setSavedFiltersState(appliedFilters)
            resetAppliedFilters()
            setSavedTypologyState(appliedTypology)
            setAppliedTypology(null);
        }, [setSavedFiltersState, appliedFilters, resetAppliedFilters, setSavedTypologyState, appliedTypology, setAppliedTypology]),
        restoreState: useCallback(() => {
            setAppliedFilters(savedFiltersState)
            setSavedFiltersState(initialFilters)
            setAppliedTypology(savedTypologyState)
            setSavedTypologyState(null);
        }, [setAppliedFilters, savedFiltersState, setSavedFiltersState, setSavedTypologyState, appliedTypology, setAppliedTypology]),
        filtersList: useMemo(() => [].concat(...Object.values(appliedFilters)), [appliedFilters]),
        dateRange,
        setDateRange: setDateRange,
        limit,
        setLimit,
        typology: appliedTypology,
        setTypology: setAppliedTypology,
    }
}

const initialFilters = {
    traffic: [],
    source: [],
    device: [],
    contentType: [],
    platform: [],
    user: [],
    country: [],
    temporality: [],
    author: [],
    category: [],
    language: [],
    topics: [],
    campaign: []
};

export default useFiltersContext