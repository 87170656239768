import React, {useMemo, useState} from "react";
import {FilterGroups} from "../../domain/Filters";
import Skeleton from "../Common/Loading/Skeleton";
import {useTranslation} from "react-i18next";
import SimpleTooltip from "../Common/SimpleTooltip";
import {addThousandsSeparator} from "../../utils/number";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import {useAutoAnimate} from "@formkit/auto-animate/react";

export interface BarListProps {
    title: string
    group: FilterGroups
    data: BarListData[]|null
    className?: string
    percentage?: boolean
    active?: boolean
}

export interface BarListData {
    name: string
    value: number
}

const HIDE_LIMIT = 6;

const FiltersBarList = ({title, data, className, group, percentage = true, active = true}: BarListProps) => {
    const {t} = useTranslation()
    const {filtersList, add, remove} = useFiltersContext()
    const total = useMemo(() =>
        data?.reduce((sum, obj) => sum + obj.value, 0) ?? 0,
    [data]
    );
    const [limit, setLimit] = useState(HIDE_LIMIT);
    const [parent] = useAutoAnimate();

    const items = useMemo(() => limit !== 0 ? data?.slice(0, limit) : data, [data, limit])

    if (!data) {
        return <div className={`cc-chart-bar ${className ? className : ''}`}>
            <div className="cc-chart-bar__title">{title}</div>
            <Skeleton type="filter"/>
        </div>
    }

    return (
        <>
            <div className={`cc-chart-bar ${className ? className : ''}`} ref={parent}>
                <div className="cc-chart-bar__title">{title}</div>
                { items?.map((item) => {
                    const percent = item.value !== 0 ? ((item.value / total * 100)) : 0;
                    const percentValue = percent <= 0.1 && percent > 0 ? "0.1" : percent.toFixed(percent < 10 && percent > 0 ? 1 : 0);

                    return <div
                        className={active ? ('cc-chart-bar__item' + (filtersList.includes(item.name) ? " is-active" : "")) : ""}
                        key={item.name}
                        data-type={item.name.toLowerCase()}
                        onClick={() => {
                            if (active) {
                                filtersList.includes(item.name) ? remove(group, item.name) : add(group, item.name)
                            }
                        }}
                    >
                        <div className="cc-chart-bar__wrapper oc-group oc-group--no-gap oc-group--nowrap">
                            {active ? <SimpleTooltip content={t(item.name)}>
                                <div className="cc-chart-bar__item-name" title={t(item.name) ?? ""}>{t(item.name)}</div>
                            </SimpleTooltip>: <div className="cc-chart-bar__item-name" title={t(item.name) ?? ""}>{t(item.name)}</div>}
                            { percentage && <div className="cc-chart-bar__item--wrapper" style={active ? { position: 'inherit'}: {}}>
                                <div className="cc-chart-bar__item-graph" style={{ width: percentValue + '%'}}></div>
                            </div> }
                        </div>
                        <div className="cc-chart-bar__item-value">{percentage ? (percentValue + "%") : addThousandsSeparator(item.value)}</div>
                    </div>
                }) }
                { (limit !== 0 && data.length > HIDE_LIMIT) &&
                    <div className={"mc-button mc-button--link"} onClick={() => setLimit(0)}>
                        {t("view more")}
                    </div>
                }
                { limit === 0 &&
                    <div className={"mc-button mc-button--link"} onClick={() => setLimit(HIDE_LIMIT)}>
                        {t("reduce")}
                    </div>
                }
            </div>
        </>
    );
}

export default FiltersBarList