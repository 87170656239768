import React, {ReactNode, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    children: ReactNode
    onChange?: (check: boolean) => void
    defaultChecked?: boolean
}

const Option = ({children, onChange, defaultChecked = false}: Props) => {
    const {t} = useTranslation()
    const [checked, setChecked] = useState<boolean>(defaultChecked)

    const handleClick = useCallback(() => {
        const value = !checked;

        setChecked(value);

        if (onChange) onChange(value)
    }, [setChecked, checked, onChange])

    return <div className='cc-filter__group-item'>
        <input type="checkbox" className="cc-form__checkbox" checked={checked} onChange={handleClick}/>
        <label className="cc-form__checkbox-label">
            <span className="cc-form__checkbox-icon" onClick={handleClick}></span>
            {children ? t(children.toString()) : ''}
        </label>
    </div>
}

export default Option