import React, {memo} from "react";
import IconGeneric from "../Icon/IconGeneric";
import {Tooltip} from "@mui/material";

interface Props {
    title: string
    size?: number|undefined
}

const InfoTooltip = memo(({title, size = 16}: Props) => {
    return <Tooltip title={title}>
        <div className="cc-tooltip cc-tooltip--info">
            <IconGeneric iconName="info-ico" className={"mc-icon__generic--size-" + size.toString()} />
        </div>
    </Tooltip>
})

export default InfoTooltip