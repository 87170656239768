import {useCallback} from "react";

const useDates = () => {
    const getFirstDayTime = (date: Date) => {
        return new Date(date.setHours(0, 0, 0, 0));
    }

    const getLastDayTime = (date: Date) => {
        return new Date(date.setHours(23, 59, 59, 999));
    }

    const formatDate = useCallback((date: Date|null) => {
        if (!date) {
            return '--/--/--'
        }

        return date.getDate().toString().padStart(2, '0')
            + '/' + (date.getMonth() + 1).toString().padStart(2, '0')
            + '/' + date.getFullYear()
    }, []);

    const formatDateTime = (date: Date|null): string => {
        return formatDate(date) + ' ' + formatTime(date);
    }

    const formatTime = (date: Date|null): string => {
        if (!date) {
            return '--:--'
        }

        return (date.getHours()).toString().padStart(2, '0') + ':' + (date.getMinutes()).toString().padStart(2, '0')
    }

    const isValidDate = (text: string) => {
        const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
        if (!regex.test(text)) {
            return false;
        }

        const [day, month, year] = text.split('/');
        const date = new Date(Number(year), Number(month) - 1, Number(day));

        return (
            date.getDate() === Number(day) &&
            date.getMonth() === Number(month) - 1 &&
            date.getFullYear() === Number(year)
        );
    }

    const convertStringToDate = (text: string) => {
        if (isValidDate(text)) {
            const [day, month, year] = text.split('/');

            return new Date(Number(year), Number(month) - 1, Number(day));
        }

        return null
    }

    return {
        getFirstDayTime,
        getLastDayTime,
        formatDate,
        isValidDate,
        convertStringToDate,
        formatDateTime,
        formatTime,
    }
}

export default useDates;