import {AnalyticsData} from "../use-analytics-data";
import {Content, TypologyContent} from "../../domain/Content";
import {createContext, Dispatch, SetStateAction, useContext} from "react";
import {BarListData} from "../../components/FilterBar/FiltersBarList";
import {FiltersList} from "../../domain/Filters";
import {Sorting} from "../../domain/Sorting";

export interface ContentsContextInterface {
    analyticsData: AnalyticsData,
    selectContent: (content: Content|TypologyContent) => void
    filters: FiltersList
    sorting: Sorting|null,
    setSorting: Dispatch<SetStateAction<Sorting|null>>
    accumulatedSourceStats: BarListData[]|null
    accumulatedContentTypeStats: BarListData[]|null
    accumulatedCountryStats: BarListData[]|null
    accumulatedLanguageStats: BarListData[]|null
    accumulatedTopicStats: BarListData[]|null
    accumulatedCampaignStats: BarListData[]|null
}

export const ContentsContext = createContext<ContentsContextInterface>({} as ContentsContextInterface)

const useContentsContext = () => useContext(ContentsContext)

export default useContentsContext