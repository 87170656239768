import Content from "./Content";
import {
    Content as DomainContent,
    TypologyContent as DomainTypologyContent
} from "../../domain/Content";
import {useTranslation} from "react-i18next";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import ContentsHeader from "./ContentsHeader";
import MoreContents from "./MoreContents";
import ContentsColumnsHeader from "./ContentsColumnsHeader";

interface Props {
    data: DomainContent[]|DomainTypologyContent[]|null
}

const TypologyContents = ({data}: Props) => {
    const {t} = useTranslation();
    const [parent, enableAnimations] = useAutoAnimate();

    return (
        <>
            <ContentsHeader />
            <div className="cc-content-list" ref={parent}>
                <ContentsColumnsHeader />
                { data?.length === 0 &&
                    <div className="cc-content-list__no-results">
                        <div className="cc-content-list__no-results--omg">(≥o≤)</div>
                        <div className="cc-content-list__no-results--title">{t("no results")}</div>
                        <div className="cc-content-list__no-results--subtitle">{t("try to modify the active filters to get results")}</div>
                    </div>
                }
                { data?.map((content) => {
                    return <Content key={(content as DomainContent).url + content.title} content={content as DomainContent}/>
                })}
                <MoreContents data={data} enableAnimations={enableAnimations} />
            </div>
        </>
    )
}

export default TypologyContents