import React, {useMemo} from "react";
import useNumberUnitResolver from "../../hooks/use-number-unit-resolver";
import {addThousandsSeparator} from "../../utils/number";

interface Props {
    label: string
    value: string
    compareValue: string
    color: string
    total: number
    compareTotal: number
}

const ChartTooltipItem = ({label, value, compareValue, color, total, compareTotal}: Props) => {
    const val = useMemo(() => parseInt(value), [value])
    const compareVal = useMemo(() => parseInt(compareValue), [compareValue])
    const biggerTotal = useMemo(() => Math.max(total, compareTotal), [total, compareTotal])

    const {resolvedValue, unit} = useNumberUnitResolver(val)
    const {resolvedValue: compareResolvedValue, unit: compareUnit} = useNumberUnitResolver(compareVal)

    const percent = val / biggerTotal * 100;
    const comparePercent = compareVal / biggerTotal * 100

    const diff = useMemo(() => {
        if (compareVal === 0) {
            return val === 0 ? 0 : Number.NaN;
        }

        return (val - compareVal) / compareVal * 100
    }, [val, compareVal])

    const diffClass = useMemo(() => {
        if (diff > 0) return "is-positive";
        if (diff < 0) return "is-negative";
        return "is-zero";
    }, [diff])

    return (
        <div className="cc-chart__tooltip-wrapper">
            <div className="cc-chart__tooltip-item" style={{color: color}}>
                <span className="cc-chart__tooltip-item-name">{label}</span>
                <div className="cc-chart__tooltip-item-bar">
                    <div className="cc-chart__tooltip-item-bar--new" style={{width: Number.isFinite(percent) ? percent+'%' : '0%', backgroundColor: color}}></div>
                    <div className="cc-chart__tooltip-item-bar--old" style={{width: Number.isFinite(comparePercent) ? comparePercent+'%' : '0%', backgroundColor: color}}></div>
                </div>

                <div className="cc-chart__tooltip-item-value-wrapper">
                    <div className="cc-chart__tooltip-item-value">
                        <div className="cc-chart__tooltip-item-value--new">{resolvedValue}{unit}</div>
                        <div className="cc-chart__tooltip-item-value--old">{compareResolvedValue}{compareUnit}</div>
                    </div>
                    <div className="cc-chart__tooltip-item-average">
                        <div className={diffClass}>
                            <span>(</span>
                            {Number.isNaN(diff) && '-'}
                            {Number.isFinite(diff) && addThousandsSeparator(parseFloat(diff.toFixed(diff < 10 && diff > -10 && diff !== 0 ? 1 : 0))) + "%"}
                            <span>)</span>
                        </div>
                    </div>
                </div>

                {/* <div className="cc-chart__tooltip-item-average" style={{ background: 'pink'}}>
                    <div className={diffClass}>
                        {Number.isNaN(diff) && '-'}
                        {Number.isFinite(diff) && diff.toFixed(diff < 10 && diff > -10 && diff !== 0 ? 1 : 0) + "%"}
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ChartTooltipItem