import caDummy from "../../../assets/ca.jpg";
import React, {memo} from "react";

interface Props {
    image?: string|null|undefined
    alt: string
}

const ListImage = memo(({image, alt}: Props) => {
    return <div className='cc-content-list__picture'>
        <img
            className="cc-content-list__picture--image"
            src={image && image.length > 0 ? image : caDummy}
            alt={alt}
        />
    </div>
});

export default ListImage