import Skeleton from "../../Common/Loading/Skeleton";
import IconGeneric from "../../Icon/IconGeneric";
import {useTranslation} from "react-i18next";

interface Props {
    label: string
    items: string[]|null
    selected: string
    onSelect: (value: string) => void
    onEdit: (value: string, index: number) => void
}

const Suggest = ({label, items, selected, onSelect, onEdit}: Props) => {
    const {t} = useTranslation()
    if (items === null) {
        return <div className={"cc-ai__item"}>
            <label className="cc-ai__item-title">{label}</label>
            <Skeleton type={"ai"} />
        </div>
    }

    return <>
        <div className={"cc-ai__item"}>
            <label className="cc-ai__item-title">{label}</label>
            <ul className="cc-ai__item-list">
                { items.map((value, index) => {
                    return <>
                    
                        <li className={"cc-ai__item-proposal" + (value === selected ? " is-selected" : "")} key={index + value}>
                            <div
                                onClick={() => onSelect(value === selected ? "" : value)}
                                className={"cc-ai__selector" + (value === selected ? " .is-selected" : "")}
                                title={t('select') || ''}
                            >
                                {(value === selected ? <IconGeneric iconName="check-ico" className="mc-icon__generic--size-12 is-success" /> : "")}
                            </div>
                            <div className="cc-ai__item-proposal--content"
                                title={t('edit') || ''}
                                onBlur={(e) => {
                                    onEdit(e.target.innerText, index);

                                    if (value === selected) {
                                        onSelect(e.target.innerText)
                                    }
                                }}
                                contentEditable={true}
                                suppressContentEditableWarning={true}>
                                {value}
                            </div>
                        </li>
                    
                    </>
                }) }
            </ul>
        </div>
    </>
}

export default Suggest