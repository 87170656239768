import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IconGeneric from '../Icon/IconGeneric';
import SearchSuggestions from './SearchSuggestions';

function Searcher() {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleInputClick = () => {
        setIsOpen(true);
    };

    const closeSuggestions = () => {
        setSearchValue("");
    };

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpen]);

    return (
        <>
            <div className={`mc-button mc-button--secondary mc-button--no-border cc-header__search ${isOpen ? 'is-open' : ''}`} onClick={() => setIsOpen(prevState => !prevState)}>
                <IconGeneric iconName={isOpen ? "close-ico" : "search-ico"} className="mc-icon__generic--size-16" />
            </div>
            <div className={`cc-header__search-input-wrapper ${isOpen ? 'is-open' : ''}`}>

                <div className={`mc-button cc-header__search cc-header__search--inner ${isOpen ? 'is-open' : ''}`}  onClick={() => { setIsOpen(prevState => !prevState); closeSuggestions(); }}>
                    <IconGeneric iconName={isOpen ? "close-ico" : "search-ico"} className="mc-icon__generic--size-16" />
                </div>

                <input
                    ref={inputRef}
                    className='cc-header__search-input'
                    placeholder={t('search content')}
                    value={searchValue}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                />
                <SearchSuggestions
                    searchValue={searchValue}
                    onClose={closeSuggestions}
                />
            </div>
        </>
    );
}

export default Searcher;
