import {useParams} from "react-router-dom";
import useAuthentication from "../hooks/use-authentication";
import {useEffect} from "react";
import logoDesktop from '../assets/logo-analytics-desktop.svg';
import {useTranslation} from "react-i18next";
import Loading from '../components/Common/Loading/Loading';

const Authentication = () => {
    const {t} = useTranslation();
    const {token} = useParams()
    const {authenticate} = useAuthentication();

    useEffect(() => {
        if (token) authenticate(token)
    }, [token])

    return <div className='oc-flex oc-flex--center is-static-page'>
        <div className="cc-card cc-card--static">
            <img className="mc-logo__image--static" src={ logoDesktop } alt={"Logo Comitium Analytics"} />
            <p>{t('verifying your access credentials')}</p>
            <Loading/>
        </div>
    </div>
}

export default Authentication