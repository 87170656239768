import React from 'react';

const Skeleton = ({ type }: { type: "filter"|"card"|"ai"|string }) => {
    if (type === 'filter') {
        return <div className='cc-skeleton'>
            <span className="cc-skeleton__item" style={{ height: '15px' }}></span>
            <span className="cc-skeleton__item" style={{ height: '15px' }}></span>
            <span className="cc-skeleton__item" style={{ height: '15px' }}></span>
            <span className="cc-skeleton__item" style={{ height: '15px' }}></span>
            <span className="cc-skeleton__item" style={{ height: '15px' }}></span>
        </div>
    }

    if (type === 'card') {
        return <div className='cc-skeleton'>
            <span className="cc-card__value cc-skeleton__item" style={{ height: '30px' }}></span>
            <span className="cc-card__status cc-skeleton__item" style={{ height: '10px' }}></span>
        </div>
    }

    if (type === 'ai') {
        return <div className='cc-skeleton'>
            <span className="cc-skeleton__item cc-skeleton__item--ai" style={{ height: '36px' }}></span>
            <span className="cc-skeleton__item cc-skeleton__item--ai" style={{ height: '36px' }}></span>
            <span className="cc-skeleton__item cc-skeleton__item--ai" style={{ height: '36px' }}></span>
        </div>
    }

    return <div className='c-skeleton'><span className="cc-skeleton__item" style={{ height: '20px' }}></span></div>;
};

export default Skeleton;
