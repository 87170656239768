export type DateRangeOptions = 'custom'|'today'|'realtime 5 minutes'|'realtime 30 minutes'|'yesterday'|'last 7 days'|'last 30 days'|'current month'|'last month';

export interface DateRange {
    label: DateRangeOptions
    start: Date|null
    end: Date|null
}

export const dateRangeOptions: DateRangeOptions[] = [
    'realtime 5 minutes',
    'realtime 30 minutes',
    'today',
    'yesterday',
    'last 7 days',
    'last 30 days',
    'current month',
    'last month',
];
