import logoDesktop from '../assets/logo-analytics-desktop.svg';
import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";
import useAuthentication from "../hooks/use-authentication";
import logoComitium from '../assets/logo-comitium.svg';

const Login = () => {
    const {t} = useTranslation();
    const [publicKey, setPublicKey] = useState<string>("");
    const [privateKey, setPrivateKey] = useState<string>("");
    const {login, error, isAuthenticating} = useAuthentication();

    const canSubmit = useCallback((): boolean => {
        return !isAuthenticating && publicKey.length > 0 && privateKey.length > 0
    }, [isAuthenticating, publicKey, privateKey])

    const submit = useCallback(() => {
        if (canSubmit()) login(publicKey, privateKey)
    }, [publicKey, privateKey, login, canSubmit])

    return <div className='oc-flex oc-flex--center is-static-page'>
        <div className="cc-login">
            <div className="cc-login__wrapper">
                <img className="mc-logo__image--static" src={ logoDesktop } alt={"Logo Comitium Analytics"} />
                <div className={"cc-login__form"}>
                    <label>{t('public key')}</label>
                    <input
                        type={"text"}
                        value={publicKey}
                        onChange={(e) => setPublicKey(e.target.value)}
                        className={"cc-form__input"}
                    />
                </div>
                <div className={"cc-login__form"}>
                    <label>{t('secret key')}</label>
                    <input
                        type={"password"}
                        value={privateKey}
                        onChange={(e) => setPrivateKey(e.target.value)}
                        className={"cc-form__input"}
                    />
                </div>
                { error &&
                        <div className="cc-login__error">{t(error)}</div>
                }
                <div className={"mc-button mc-button--primary" + (!canSubmit() ? " is-disabled" : "")} onClick={submit}>{t('login')}</div>
            </div>
            <a href="https://comitium.io" target="_blank" rel="noreferrer"><img className="mc-logo__image--static mc-logo--comitium-login" src={logoComitium} alt={"Logo Comitium"} /></a>
        </div>
    </div>
}

export default Login