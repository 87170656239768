export interface Content {
    id: number
    accountToken: string
    image: string
    title: string
    locale: string
    type: string
    category: string
    publishDate: number
    modificationDate: number
    author: string
    pageViews: number
    users: number
    readingTime: number
    url: string
    topics: string[]
}

export interface TypologyContent {
    title: string
    pageViews: number
    users: number
    readingTime: number
    contents: number
    referer?: string
}

export interface SearchContent {
    id: number
    title: string
    type: string
    image: string
    category: string
    author: string
    locale: string
    url: string
    accountToken: string
    publishDate: number
}

export type ContentTypologies = "categories"|"authors"|"topics"|"not_found";

export const CONTENTS_LIMIT = 10;
export const CONTENTS_PAGINATE = 5;