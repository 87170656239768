export const formatContentDateTime = (date: Date, locale = 'es-ES'): string => {
    return date.toLocaleString(locale, {
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit'
    }) + (locale.startsWith('en') ? "" : "h")
}

export const formatContentDate = (date: Date,  locale = 'es-ES'): string => {
    return date.toLocaleString(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    })
}