import "react-datepicker/dist/react-datepicker.css";

import React, {Dispatch, MutableRefObject, SetStateAction, useRef} from "react";
import {useTranslation} from "react-i18next";
import DatePicker, {registerLocale} from "react-datepicker";
import useDates from "../../hooks/use-dates";
import ca from "date-fns/locale/ca";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-GB";
import eu from "date-fns/locale/eu";
import pt from "date-fns/locale/pt";
import {DateRangeOptions} from "../../domain/DateRange";

interface Props {
    setSelectedOption: Dispatch<SetStateAction<DateRangeOptions>>
    startDate: Date|null
    setStartDate: Dispatch<SetStateAction<Date|null>>
    endDate: Date|null
    setEndDate: Dispatch<SetStateAction<Date|null>>
}

export const CustomDate = ({setSelectedOption, startDate, setStartDate, endDate, setEndDate}: Props) => {
    const {t, i18n} = useTranslation()
    const {getFirstDayTime, getLastDayTime, formatDate, isValidDate, convertStringToDate} = useDates()
    const refStart: MutableRefObject<any> = useRef()
    const refEnd: MutableRefObject<any> = useRef()

    registerLocale("ca", ca);
    registerLocale("es", es);
    registerLocale("en", en);
    registerLocale("eu", eu);
    registerLocale("pt", pt);

    const filterDate = (day: Date) => {
        return day < new Date();
    };

    const handleCalendar = (dates: [start: Date, end: Date|null]) => {
        const [start, end] = dates;
        setSelectedOption('custom');
        setStartDate(start);
        setEndDate(end ? getLastDayTime(end) : null);
        refStart.current.value = formatDate(start)
        refEnd.current.value = formatDate(end ?? start)
    };

    const handleDateInput = (value: string, field: 'start'|'end') => {
        if (isValidDate(value)) {
            const [day, month, year] = value.split('/');
            const date = new Date(Number(year), Number(month)-1, Number(day));

            if (field === 'start') setStartDate(date)
            if (field === 'end') setEndDate(date)
        }
    }

    const blurStartInput = (value: string) => {
        let date = convertStringToDate(value)

        if (!date) {
            setStartDate(null)
            setEndDate(null)
            refStart.current.value = formatDate(null)
            refEnd.current.value = formatDate(null)
        } else {
            const now = new Date()
            if (date > now) {
                date = now
            }

            setStartDate(getFirstDayTime(date))
            refStart.current.value = formatDate(date)

            if (endDate && date > endDate) {
                setEndDate(getLastDayTime(date))
                refEnd.current.value = formatDate(date)
            }
        }
    }

    const blurEndInput = (value: string) => {
        let date = convertStringToDate(value)

        if (!date) {
            setEndDate(null)
            refEnd.current.value = formatDate(null)
        } else {
            const now = new Date()
            if (date > now) {
                date = now
            }

            setEndDate(getLastDayTime(date))
            refEnd.current.value = formatDate(date)

            if (startDate && date < startDate) {
                setStartDate(getFirstDayTime(date))
                refStart.current.value = formatDate(date)
            }
        }
    }

    return (
        <div className="cc-filter__list cc-filter__list--interval">
            <div className="cc-date-picker">
                <DatePicker
                    selected={startDate}
                    onChange={handleCalendar}
                    startDate={startDate}
                    endDate={endDate}
                    locale={i18n.language}
                    filterDate={filterDate}
                    selectsRange
                    inline
                />
            </div>
            <div className="cc-filter__group cc-filter__group--interval">
                <div className="cc-filter__group-item cc-filter__group-item--interval">
                    <span>{t('from')}:</span>
                    <input
                        className='cc-form__input'
                        type="text"
                        defaultValue={formatDate(startDate)}
                        onChange={(e) => {
                            handleDateInput(e.target.value, 'start')
                        }}
                        onBlur={(e) => {
                            blurStartInput(e.target.value)
                        }}
                        ref={refStart}
                    />
                </div>
                <div className="cc-filter__group-item cc-filter__group-item--interval">
                    <span>{t('to')}:</span>
                    <input
                        className='cc-form__input'
                        type="text"
                        defaultValue={formatDate(endDate)}
                        onChange={(e) => {
                            handleDateInput(e.target.value, 'end')
                        }}
                        onBlur={(e) => {
                            blurEndInput(e.target.value)
                        }}
                        ref={refEnd}
                    />
                </div>
            </div>
        </div>
    )
}