import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useDateFiltersHelper from "../../../hooks/filters/use-date-filters-helper";
import useFiltersContext from "../../../hooks/contexts/use-filters-context";

interface Props {
    quantity: number
}

const ContentSkeleton = memo(({quantity}: Props) => {
    const {t} = useTranslation()
    const {isRealTime} = useDateFiltersHelper()
    const {typology} = useFiltersContext();

    return <>
        { Array.from({length: quantity}).map((value, index) => {
            return <div key={index} className='cc-content-list__item is-skeleton'>
                { typology !== "not_found" &&
                <>
                    { !typology &&
                        <div className={"cc-content-list__picture"}>
                            <span className="cc-skeleton__item" style={{ height: '93px' }}></span>
                        </div>
                    }
                    <div className={"cc-content-list__article"}>
                        <span className="cc-skeleton__item" style={{ height: '32px' }}></span>
                        <span className="cc-skeleton__item" style={{ height: '12px' }}></span>
                    </div>
                    <div className={"cc-content-list__data"}>
                        <div className={"cc-content-list__data--pages"}>
                            <span className={"cc-content-list__data--label"}>{t("pageviews")}</span>
                            <div className={"oc-group oc-group--no-gap"}>
                                <span className="cc-skeleton__item" style={{ height: '40px' }}></span>
                            </div>
                        </div>
                        <div className={"cc-content-list__data--time"}>
                            <span className={"cc-content-list__data--label"}>{t("reading time")}</span>
                            <div className={"oc-group oc-group--no-gap"}>
                                <span className="cc-skeleton__item" style={{ height: '40px' }}></span>
                            </div>
                        </div>
                        <div className={"cc-content-list__data--concurrence"}>
                            <span className={"cc-content-list__data--label"}>{t(isRealTime ? "active users" : "unique users")}</span>
                            <div className={"oc-group oc-group--no-gap"}>
                                <span className="cc-skeleton__item" style={{ height: '40px' }}></span>
                            </div>
                        </div>
                    </div>
                </>
                }

                { typology === "not_found" &&
                    <>
                        <div className={"cc-content-list__article cc-content-list__article--404"}>
                            <span className="cc-skeleton__item" style={{ height: '32px' }}></span>
                            <span className="cc-skeleton__item" style={{ height: '12px' }}></span>
                        </div>
                        <div className={"cc-content-list__data cc-content-list__data--404"}>
                            <div className={"cc-content-list__data--pages"}>
                                <span className={"cc-content-list__data--label"}>{t("pageviews")}</span>
                                <div className={"oc-group oc-group--no-gap"}>
                                    <span className="cc-skeleton__item" style={{ height: '40px' }}></span>
                                </div>
                            </div>
                            <div className={"cc-content-list__data--concurrence"}>
                                <span className={"cc-content-list__data--label"}>{t(isRealTime ? "active users" : "unique users")}</span>
                                <div className={"oc-group oc-group--no-gap"}>
                                    <span className="cc-skeleton__item" style={{ height: '40px' }}></span>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        }) }
        <div className="cc-content-more">
            <div className={"mc-button is-disabled"}>
                {t('view more')}
            </div>
        </div>
    </>
})

export default ContentSkeleton