import FiltersBarList, {BarListProps} from "./FiltersBarList";
import {useMemo} from "react";

const OrderedFiltersBarList = (props: BarListProps) => {
    const orderedData = useMemo(() => {
        return props.data?.sort((a, b) => b.value - a.value) ?? props.data;
    }, [props.data])

    return <FiltersBarList {...props} data={orderedData}/>
}

export default OrderedFiltersBarList