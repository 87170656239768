import React, {memo} from "react";

interface Props {
    quantity: number
}

const SearchSkeleton = memo(({quantity}: Props) => {
    return <>
        { Array.from({length: quantity}).map((value, index) => {
            return <div key={index} className='cc-content-list__item_ cc-header__search-suggest-item'>
                <div className={"cc-content-list__picture"}>
                    <span className="cc-skeleton__item" style={{ height: '52px' }}></span>
                </div>
                <div className={"cc-content-list__article"}>
                    <span className="cc-skeleton__item" style={{ height: '14px' }}></span>
                    <span className="cc-skeleton__item" style={{ height: '14px' }}></span>
                </div>
            </div>
        }) }
    </>
})

export default SearchSkeleton