import {useCallback, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";

interface UpdateContentProps {
    account: string
    locale: string
    url: string
    title: string
    metadata: {
        title: string
        description: string
    }
}

const useAiContent = (token: string, url: string, locale: string) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [props, setProps] = useState<UpdateContentProps>(initialContentProps(token, url, locale));

    const canSubmit = useMemo(
        () => props.title !== "" || props.metadata.title !== "" || props.metadata.description !== "",
        [props]
    );

    const updateContent = useCallback(async () => {
        if (!canSubmit || isLoading) {
            return;
        }

        setLoading(true);
        setError(false);

        const client = new ApiClient();

        try {
            await client.request("put", "/contents", props)
        } catch (exception) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [setLoading, setError, props, token, url, canSubmit, isLoading])

    const setTitle = useCallback((title: string) => {
        setProps((current) => ({...current, title}))
    }, [setProps]);

    const setMetaTitle = useCallback((title: string) => {
        setProps((current) => ({...current, metadata: {
            ...current.metadata,
            title,
        }}))
    }, [setProps]);

    const setMetaDescription = useCallback((description: string) => {
        setProps((current) => ({...current, metadata: {
            ...current.metadata,
            description,
        }}))
    }, [setProps]);

    return {
        props,
        setProps,
        updateContent,
        isLoading,
        error,
        setTitle,
        setMetaTitle,
        setMetaDescription,
        canSubmit,
    }
}

const initialContentProps = (token: string, url: string, locale: string): UpdateContentProps => ({
    title: "",
    metadata: {
        title: "",
        description: "",
    },
    account: token,
    locale: locale,
    url,
})

export default useAiContent;