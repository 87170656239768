import axios, {AxiosResponse} from "axios";
import {COMITIUM_URL_KEY, TOKEN_KEY} from "../domain/Constants";

class ApiClient {
    private readonly url: string
    private readonly token: string
    private abortController: AbortController|undefined

    constructor() {
        this.url = process.env.NODE_ENV === "development" ? "http://localhost:3010/api" : "/api"
        this.token = localStorage.getItem(TOKEN_KEY) ?? ""
    }

    public async login(token: string) {
        const response = await axios({
            method: "post",
            url: this.url + "/auth-login",
            headers: {
                Authorization: token
            }
        })

        localStorage.setItem(TOKEN_KEY, response.data.token)
    }

    public logout()
    {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(COMITIUM_URL_KEY)
    }

    public abort()
    {
        this.abortController?.abort()
    }

    public request(method: methodTypes, endpoint: string, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method,
            url: this.url + endpoint,
            headers: {
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        if (method === "get") {
            Object.assign(params, {params: parameters})
        } else {
            Object.assign(params, {data: parameters})
        }

        return axios(params)
    }

    public async getToken(publicKey: string, secretKey: string): Promise<string|null> {
        try {
            const response = await axios({
                method: "post",
                url: this.url + "/auth",
                data: {
                    publicKey,
                    secretKey,
                }
            })

            return response.data.token ?? null
        } catch(error) {
            return null;
        }
    }
}

type methodTypes = "get"|"post"|"put"|"delete";

export default ApiClient