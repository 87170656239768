import logoDesktop from '../assets/logo-analytics-desktop.svg';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import logoComitium from '../assets/logo-comitium.svg';

const Unauthorized = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return <div className='oc-flex oc-flex--center is-static-page'>
        <div className="cc-login">
            <div className="cc-login__wrapper">
                <img className="mc-logo__image--static" src={ logoDesktop } alt={"Logo Comitium Analytics"} />
                <p>{t('login to continue')}</p>
                <div className="mc-button mc-button--primary" onClick={() => {
                    navigate("/login")
                }}>{t('login')}</div>
            </div>
            <a href="https://comitium.io" target="_blank" rel="noreferrer"><img className="mc-logo__image--static mc-logo--comitium-login" src={logoComitium} alt={"Logo Comitium"} /></a>
        </div>
    </div>
}

export default Unauthorized