import React, {ReactNode} from "react";

interface Props {
    title: string
    children: ReactNode
    isMain?: boolean
}

const Card = ({title, children, isMain = false}: Props) => {
    return (
        <div className={"cc-card" + (isMain ? ' cc-card--main' : '')}>
            <span className="cc-card__title">
                {title}
            </span>
            {children}
        </div>
    )
}

export default Card