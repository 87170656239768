import React, {useMemo} from "react";
import Card from "./Card";
import TimeCounter from "../Common/Counters/TimeCounter";
import {CardData} from "../../domain/CardData";
import Skeleton from "../Common/Loading/Skeleton";

interface Props {
    title: string
    data?: CardData
}

const TimeCard = ({title, data}: Props) => {
    const comparePercentage = useMemo(() => {
        if (!data) {
            return 0
        }
        return (((data.value - data.compare) / data.compare) * 100).toFixed()
    }, [data])

    if (!data) {
        return <Card title={title}>
            <Skeleton type="card"/>
        </Card>
    }

    return <Card title={title}>
        <div className="oc-group oc-group--no-gap">
            <TimeCounter seconds={data.value} classNamePrefix={"cc-card__"} />
        </div>
        {(data.compare !== null) &&
            <span className={"cc-card__status" + (data.value !== data.compare ? (data.value > data.compare ? " cc-card__status--positive" : " cc-card__status--negative") : "")}>
                {comparePercentage}%
            </span>
        }
    </Card>
}

export default TimeCard