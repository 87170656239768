import IconGeneric from "../../Icon/IconGeneric";
import {Tooltip} from "@mui/material";
import React, {memo, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    textToCopy: string
}

const CopyButton = memo(({textToCopy}: Props) => {
    const {t} = useTranslation();
    const [isCopied, setCopied] = useState<boolean>(false);

    const copyToClipboard = useCallback(async () => {
        setCopied(true);
        await navigator.clipboard.writeText(textToCopy)
    }, [textToCopy])

    const resetCopyToClipboard = useCallback(() => {
        setTimeout(() => setCopied(false), 150);
    }, [])

    return <Tooltip
        title={isCopied ? t("copied") : t("copy")}
        placement={"top"}
        arrow={true}
        onClick={copyToClipboard}
        onClose={resetCopyToClipboard}
    >
        <span>
            <IconGeneric iconName="copy-ico" className="mc-icon__generic--size-18 mc-icon--button" />
        </span>
    </Tooltip>
});

export default CopyButton