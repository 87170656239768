import {useCallback, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";

export interface AISuggestProps  {
    title?: string[]
    metadata?: {
        title?: string[]
        description?: string[]
    }
}

interface AISuggestResponse {
    response: AISuggestProps
}

const useAiSuggest = () => {
    const [suggestProps, setSuggestProps] = useState<AISuggestProps|null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const client = useMemo(() => new ApiClient(), [])

    const fetchSuggestions = useCallback(async (url: string): Promise<void> => {
        setIsLoading(true);

        const response = await client.request("post", "/contents/suggest", {url})

        if (response.status >= 200 && response.status < 300) {
            const data = response.data as AISuggestResponse;

            setSuggestProps(data.response);
        }
        setIsLoading(false);
    }, [client])

    const setSuggestionTitle = useCallback((title: string, index: number) => {
        setSuggestProps((current) => ({
            ...current,
            title: current?.title?.map((t, i) => i === index ? title : t)
        }))
    }, [setSuggestProps])

    const setSuggestionMetaTitle = useCallback((title: string, index: number) => {
        setSuggestProps((current) => ({
            ...current,
            metadata: {
                ...current?.metadata,
                title: current?.metadata?.title?.map((t, i) => i === index ? title : t)
            }
        }))
    }, [setSuggestProps])

    const setSuggestionMetaDescription = useCallback((description: string, index: number) => {
        setSuggestProps((current) => ({
            ...current,
            metadata: {
                ...current?.metadata,
                description: current?.metadata?.title?.map((t, i) => i === index ? description : t)
            }
        }))
    }, [setSuggestProps])

    return {
        suggestions: suggestProps,
        fetchSuggestions,
        isLoading,
        setSuggestionTitle,
        setSuggestionMetaTitle,
        setSuggestionMetaDescription,
    };
}

export default useAiSuggest