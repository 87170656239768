import React, {useRef} from "react";
import useNumberUnitResolver from "../../../hooks/use-number-unit-resolver";

interface Props {
    value: number
    classNamePrefix: string
    isMain?: boolean
}

const NumberCounter = ({ value, classNamePrefix, isMain = false }: Props) => {
    const counterRef = useRef<HTMLSpanElement | null>(null);
    const {resolvedValue, unit} = useNumberUnitResolver(value)

    return <>
        <div className="oc-group oc-group--no-gap">
            <span className={classNamePrefix + "value" + (isMain ? "-main" : "")} ref={counterRef}>
                {resolvedValue}
            </span>
            { unit &&
                <span className={classNamePrefix + "unit" + (isMain ? "-main" : "")}>
                    {unit}
                </span>
            }
        </div>
    </>
};

export default NumberCounter