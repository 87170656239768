import React, {useMemo} from "react";

interface Props {
    seconds: number
    classNamePrefix: string
}

const TimeCounter = ({ seconds, classNamePrefix }: Props) => {
    const [h, m, s] = useMemo(() => {
        return [
            Math.floor(seconds / 3600),
            Math.floor((seconds % 3600) / 60),
            seconds % 60,
        ]
    }, [seconds])

    return <span className={classNamePrefix + "value"}>
        {
            h > 0 && (Math.round(h).toString().padStart(2, '0') + ":")
        }
        {
            Math.round(m).toString().padStart(2, '0') +
            ":" +
            Math.round(s).toString().padStart(2, '0')
        }
    </span>
};

export default TimeCounter