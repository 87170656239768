import IconGeneric from "../../Icon/IconGeneric";
import React, {memo} from "react";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    url: string
    classNameSuffix: string
}

const OpenLink = memo(({url, classNameSuffix}: Props) => {
    const {t} = useTranslation();

    return <Tooltip
        title={t("open link")}
        placement={"top"}
        arrow={true}
    >
        <a className={"cc-content-list__title--" + classNameSuffix} href={url} target="_blank">
            <IconGeneric iconName="open-in-new-ico" className="mc-icon__generic--size-18"/>
        </a>
    </Tooltip>
});

export default OpenLink