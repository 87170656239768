import React, { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    contents: number
}

const TypologyInfo = memo(({ contents }: Props) => {
    const { t } = useTranslation();

    return <div className="cc-content-list__info">
        <span className="cc-content-list__info--contents">
            {contents + " " + t(contents === 1 ? "content" : "contents")}
        </span>
    </div>
})

export default TypologyInfo