import React, {useRef} from "react";

interface Props {
    value: number
    classNamePrefix: string
}

const PercentCounter = ({ value, classNamePrefix }: Props) => {
    const counterRef = useRef<HTMLSpanElement | null>(null);

    return <>
        <span className={classNamePrefix + "value"} ref={counterRef}>
            { Math.round(value) }
        </span>
        <span className={classNamePrefix + "unit"}>%</span>
    </>
};

export default PercentCounter