import React from "react";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    isActive: boolean
}

const NavItem = ({children, isActive, ...props}: Props) => {
    return <span
        className={"mc-button mc-button--secondary mc-button--rounded" + (isActive ? " is-active" : "")}
        {...props}
    >
        {children}
    </span>
}

export default NavItem
