import React, {useMemo} from "react";
import {SearchContent} from "../../domain/Content";
import DomainFavIcon from "../Common/DomainFavIcon";
import ContentType from "../Common/Content/ContentType";
import PublishDate from "../Common/Content/PublishDate";
import Author from "../Common/Content/Author";
import {formatContentDateTime} from "../../utils/date";
import {useTranslation} from "react-i18next";
import ListImage from "../Common/Content/ListImage";

interface Props {
    content: SearchContent
}

const Suggestion = ({content}: Props) => {
    const {i18n} = useTranslation()
    const publishDate = useMemo(() => content.publishDate ? formatContentDateTime(new Date(content.publishDate), i18n.language) : null, [content.publishDate, i18n.language])

    return <a
        href={"/content/"+content.id}
        className="cc-header__search-suggest-item"
    >
        <ListImage alt={content.title} image={content.image} />
        <div className='cc-content-list__article'>
            {content.title}
            <div className="cc-content-list__info">
                <DomainFavIcon url={content.url} alt={content.title} />
                <ContentType type={content.type}/>
                { publishDate && <PublishDate date={publishDate} /> }
                { content.author && <Author>{content.author}</Author> }
            </div>
        </div>
    </a>
}

export default Suggestion;