import React, {ReactNode, useMemo} from "react";
import Card from "./Card";
import PercentCounter from "../Common/Counters/PercentCounter";
import {CardData} from "../../domain/CardData";
import Skeleton from "../Common/Loading/Skeleton";

interface Props {
    title: string
    data?: CardData
    invertCompare?: boolean
    tooltip?: ReactNode
}

const PercentCard = ({title, data, invertCompare = false, tooltip}: Props) => {
    const comparePercentage = useMemo(() => {
        if (!data) {
            return 0
        }
        return (data.value - data.compare).toFixed()
    }, [data])

    if (!data) {
        return <Card title={title}>
            <Skeleton type="card"/>
        </Card>
    }

    const isPositive = invertCompare ? data.value > data.compare : data.value >= data.compare

    return <Card title={title}>
        <div className="oc-group oc-group--no-gap">
            <PercentCounter value={data.value} classNamePrefix={"cc-card__"} />
            {tooltip}
        </div>
        {(data.compare !== undefined && data.compare !== 0) &&
            <span
                className={"cc-card__status" + (data.value !== data.compare ? ((invertCompare ? !isPositive : isPositive) ? " cc-card__status--positive" : " cc-card__status--negative") : "")}
            >
                {comparePercentage}%
            </span>}
    </Card>
}

export default PercentCard