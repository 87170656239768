import {useCallback, useEffect, useMemo, useState} from "react";
import ApiClient from "../../client/ApiClient";
import {DateRange} from "../../domain/DateRange";

const useAuthors = (dateRange: DateRange, contentId: number|null, category: string|null) => {
    const [authors, setAuthors] = useState<string[]>([]);

    const parameters = useMemo(() => {
        const params = {
            interval: dateRange.label
        }
        if (dateRange.start) {
            Object.assign(params, {start: dateRange.start.getTime()})
        }

        if (dateRange.end) {
            Object.assign(params, {end: dateRange.end.getTime()})
        }

        if (contentId) {
            Object.assign(params, {contentId})
        }

        if (category) {
            Object.assign(params, {category})
        }

        return params;
    },[dateRange, contentId, category])

    const getAuthors = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.request("get", "/authors", parameters)

            setAuthors(response.data)
        } catch (exception) {
            setAuthors([]);
        }
    }, [setAuthors, parameters])

    useEffect(() => {
        getAuthors().then();
    }, [getAuthors])

    return {
        authors
    }
}

export default useAuthors;