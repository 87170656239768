import useContentsContext from "../../hooks/contexts/use-contents-context";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";
import IconGeneric from "../Icon/IconGeneric";
import useFiltersContext from "../../hooks/contexts/use-filters-context";

type SortOrder = 'ASC'|'DESC';

type ActiveSort = {
    field: 'page_views'|'reading_time'|'users',
    order: SortOrder
}

const ContentsColumnsHeader = () => {
    const { isRealTime } = useDateFiltersHelper();
    const { t } = useTranslation();
    const { setSorting } = useContentsContext();
    const {typology} = useFiltersContext();

    const [activeSort, setActiveSort] = useState<ActiveSort>( {field: "page_views", order: 'DESC'});
    const [pageViewsSort, setPageViewSort] = useState<SortOrder>('DESC');
    const [readingTimeSort, setReadingTimeSort] = useState<SortOrder>('DESC');
    const [uniqueUsersSort, setUniqueUsersSort] = useState<SortOrder>('DESC');

    const togglePageViews = () => {
        setPageViewSort((prevSort) => {
            const order = activeSort.field === 'page_views' ? (prevSort === 'DESC' ? 'ASC' : 'DESC') : prevSort;
            setActiveSort({field: "page_views", order: order});
            return order;
        })
    };

    const toggleReadingTime = () => {
        setReadingTimeSort((prevSort) => {
            const order = activeSort.field === 'reading_time' ? (prevSort === 'DESC' ? 'ASC' : 'DESC') : prevSort;
            setActiveSort({field: "reading_time", order: order});
            return order;
        })
    };

    const toggleUniqueUsers = () => {
        setUniqueUsersSort((prevSort) => {
            const order = activeSort.field === 'users' ? (prevSort === 'DESC' ? 'ASC' : 'DESC') : prevSort;
            setActiveSort({field: "users", order: order});
            return order;
        })
    };

    const [isCollapse, setIsCollapse] = useState(false);
    const toggleCollapse = () => { setIsCollapse(!isCollapse); };

    useEffect(() => {
        setSorting({
            field: activeSort.field,
            order: activeSort.order
        });
    }, [activeSort]);

    return (
        <>
            <div className="cc-content-list__item cc-content-list__sort-wrapper">
                <div className="oc-group oc-group--sort" onClick={toggleCollapse}>
                    <IconGeneric iconName="sort-ico" className="mc-icon__generic--size-20" />
                    <div className="cc-content-list__sort">
                        <span className="cc-content-list__data--label">{t("sort by")}</span>
                        <IconGeneric iconName="arrow-down-ico" className={"mc-icon__generic--size-20 has-transition" + (!isCollapse ? "" : " is-rotated")} />
                    </div>
                </div>
                <div className={"cc-content-list__list has-transition" + (!isCollapse ? " is-open" : "") + (typology === "not_found" ? " cc-content-list__list--404" : "")}>
                    <div className="cc-content-list__sort-item">
                        <button className={"cc-content-list__sort-button" + (activeSort.field === "page_views" ? ' is-active' : '')} onClick={ () =>{
                            togglePageViews();
                            toggleCollapse();
                        }}>
                            <span className="cc-content-list__data--label">{t("pageviews")}</span>
                            <IconGeneric
                                iconName="arrow-sort-ico"
                                className={"mc-icon__generic--size-14 has-transition" + (pageViewsSort === 'DESC' ? " is-rotated" : "")}
                            />
                        </button>
                    </div>

                    { typology !== "not_found" && <div className="cc-content-list__sort-item">
                        <button className={"cc-content-list__sort-button" + (activeSort.field === "reading_time" ? ' is-active' : '')} onClick={() => {
                            toggleReadingTime();
                            toggleCollapse();
                        }}>
                            <span className="cc-content-list__data--label">{t("reading time")}</span>
                            <IconGeneric
                                iconName="arrow-sort-ico"
                                className={"mc-icon__generic--size-14 has-transition" + (readingTimeSort === 'DESC' ? " is-rotated" : "")}
                            />
                        </button>
                    </div> }

                    <div className="cc-content-list__sort-item">
                        <button className={"cc-content-list__sort-button" + (activeSort.field === "users" ? ' is-active' : '')} onClick={() => {
                            toggleUniqueUsers();
                            toggleCollapse();
                        }}>
                            <span className="cc-content-list__data--label">{t(isRealTime ? "active users" : "unique users")}</span>
                            <IconGeneric
                                iconName="arrow-sort-ico"
                                className={"mc-icon__generic--size-14 has-transition" + (uniqueUsersSort === 'DESC' ? " is-rotated" : "")}
                            />
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
export default ContentsColumnsHeader;