import React from "react";
import {useTranslation} from "react-i18next";
import useDates from "../../hooks/use-dates";
import ChartTooltipItem from "./ChartTooltipItem";
import ChartTooltipBasicItem from "./ChartTooltipBasicItem";

interface CustomTooltipProps {
    chartColors: any
    active?: boolean;
    payload?: any[];
    label?: string | number;
}

const ChartTooltip: React.FC<CustomTooltipProps> = ({chartColors, active, payload, label }) => {
    const {t} = useTranslation()
    const {formatDateTime} = useDates()

    if (active && payload && payload.length) {
        const now = new Date();
        const date = label ? new Date(label.toString()) : null;
        const date5minUp = date ? new Date(date.getTime() + 300000) : null;
        const showNow = (date ?? 0) < now && (date5minUp ?? 0) > now;
        const compareDate = payload[0].payload.compareDate ? new Date(payload[0].payload.compareDate) : null;

        return (
            <div className="cc-chart__tooltip">
                { payload[0].name === "compare_total"
                    ? <div className="cc-chart__tooltip-date">
                        {formatDateTime(compareDate)}
                    </div>
                    : <div className="cc-chart__tooltip-date">
                        {formatDateTime(showNow ? now : date)}
                        <span className="cc-chart__tooltip-date cc-chart__tooltip-vs">vs</span>
                        {formatDateTime(compareDate)}
                    </div>
                }

                { payload[0].name === "compare_total" &&
                    Object.keys(chartColors).map((key) => {
                        return <ChartTooltipBasicItem
                            key={key}
                            label={t(key)}
                            value={payload[0].payload['compare_'+ key] ?? '0'}
                            color={chartColors[key]}
                            total={payload[0].value}
                        />
                    })
                }

                { payload[0].name !== "compare_total" && payload.map((data, index) => {
                    return <ChartTooltipItem
                        key={index}
                        label={data.name === 'compare_total' ? t ('total') : t(data.name)}
                        value={data.name === 'compare_total' ? data.payload.total ?? '0' : data.value}
                        compareValue={data.name === 'compare_total' ? data.payload.compare_total : data.payload['compare_' + data.name]}
                        color={data.stroke}
                        total={parseInt(data.payload.total)}
                        compareTotal={parseInt(data.payload.compare_total)}
                    />
                }) }
            </div>
        );
    }
    return null;
};

export default ChartTooltip