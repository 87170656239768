import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Dashboard from "./components/Dashboard";
import Authentication from "./pages/Authentication";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Login";

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/auth/:token",
            element: <Authentication />
        },
        {
            path: "/unauthorized",
            element: <Unauthorized />
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/",
            element: <Home />,
            errorElement: <Error/>,
            children: [
                {
                    path: "/",
                    element: <Dashboard/>,
                },
                {
                    path: "/content/:contentId",
                    element: <Dashboard/>,
                },
                {
                    path: "/category/:category",
                    element: <Dashboard/>,
                },
                {
                    path: "/author/:author",
                    element: <Dashboard/>,
                },
                {
                    path: "/topics/:topic",
                    element: <Dashboard/>,
                },
            ]
        },
    ]);

    return <RouterProvider router={router} />
}

export default App