import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React, {useMemo} from "react";
import {ChartData} from "../../domain/ChartData";
import ChartTooltip from "./ChartTooltip";
import Loading from "../Common/Loading/Loading";
import useDates from "../../hooks/use-dates";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";

interface Props {
    chart: ChartData[]
}

const MainChart = ({chart}: Props) => {
    const {formatTime, formatDate} = useDates();
    const {isRealTime, isToday, isYesterday} = useDateFiltersHelper();

    const ticks: string[]|undefined = useMemo(() => {
        if (isRealTime || isToday || isYesterday) {
            return chart
                .filter((c) => {
                    const date = new Date(c.date);

                    return [0, 4, 8, 12, 16, 20].includes(date.getHours()) && date.getMinutes() === 0
                })
                .map((c) => c.date)
        }

        return chart
            .filter((c) => {
                const date = new Date(c.date);

                return date.getHours() === 0 && date.getMinutes() === 0
            })
            .map((c) => c.date)
    }, [chart, isRealTime, isToday, isYesterday])

    if (chart.length === 0) {
        return <div className="cc-chart cc-chart__main">
            <Loading />
        </div>
    }

    const chartColors = {
        direct: '#D56073',
        social: '#7A4579',
        dark_social: '#D18E10',
        seo: '#F7CA44',
        internal: '#4D7CAE',
        other: '#7DA87B',
        total: '#afafaf'
    }

    return <div className="cc-chart cc-chart__main">
        <ResponsiveContainer>
            <AreaChart data={chart}>
                <XAxis
                    ticks={ticks}
                    dataKey="date"
                    tickFormatter={(value) => {
                        if (isRealTime || isToday || isYesterday) {
                            return formatTime(new Date(value));
                        }

                        const date = new Date(value);

                        return formatDate(date);
                    }}
                />
                <YAxis tickCount={4} />
                {/* <Tooltip content={({ active, payload, label }) => <ChartTooltip active={active} payload={payload} label={label} chartColors={chartColors}/>} /> */}
                <Tooltip
                    content={({ active, payload, label }) => (
                        <ChartTooltip active={active} payload={payload} label={label} chartColors={chartColors} />
                    )}
                    offset={20}
                    allowEscapeViewBox={{x: false, y: true}}
                    isAnimationActive={false}
                />

                <Area className="cc-chart__main--area-direct" type={"basis"} dataKey={"direct"} stackId={1} stroke={chartColors.direct}  />
                <Area className="cc-chart__main--area-social" type={"basis"} dataKey={"social"} stackId={1} stroke={chartColors.social} />
                <Area className="cc-chart__main--area-dark-social" type={"basis"} dataKey={"dark_social"} stackId={1} stroke={chartColors.dark_social} />
                <Area className="cc-chart__main--area-seo" type={"basis"} dataKey={"seo"} stackId={1} stroke={chartColors.seo} />
                <Area className="cc-chart__main--area-internal" type={"basis"} dataKey={"internal"} stackId={1} stroke={chartColors.internal} />
                <Area className="cc-chart__main--area-other" type={"basis"} dataKey={"other"} stackId={1} stroke={chartColors.other} />
                <Area className="cc-chart__main--area-last" type={"basis"} dataKey={"compare_total"} stroke={chartColors.total} strokeDasharray="2 3" />
            </AreaChart>
        </ResponsiveContainer>
    </div>
}

export default MainChart