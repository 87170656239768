import React from "react";
import IconGeneric from "../Icon/IconGeneric";

interface Props {
    title: string
}

const ContentsTitle = ({title}: Props) => {
    return (
        <div className="oc-group oc-group--half-gap">
            <IconGeneric iconName="date-ico" className="mc-icon__generic--size-16" />
            <span className="cc-content-header__title">{title}</span>
        </div>
    )
}

export default ContentsTitle