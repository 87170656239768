import {useEffect, useState, useRef} from 'react';

const useScrollOverflow = (threshold = 50) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                setIsOverflow(ref.current.scrollTop > threshold);
            }
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [ref, threshold]);

    return {
        isOverflow,
        ref
    };
};

export default useScrollOverflow;