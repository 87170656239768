import IconGeneric from "../Icon/IconGeneric";
import React from "react";
import {FilterGroups} from "../../domain/Filters";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import {useTranslation} from "react-i18next";

interface Props {
    group: FilterGroups
}

const AppliedFilters = ({group}: Props) => {
    const {filters: appliedFilters, remove} = useFiltersContext()
    const {t} = useTranslation()

    return <>
        { appliedFilters[group].map((filter) => {
            return <div
                key={group + "_" + filter}
                className="cc-filter__item"
                onClick={(event) => {
                    event.stopPropagation();

                    remove(group as FilterGroups, filter)
                }}
                title={t(filter)}
            >
                <span className="cc-filter__item-text">{t(filter)}</span>
                <IconGeneric
                    iconName="close-thick-ico"
                    className="mc-icon__generic--size-7"
                />
            </div>
        }) }
    </>
}

export default AppliedFilters