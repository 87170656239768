import {useTranslation} from "react-i18next";

interface Props {
    topics: string[]
}

const ContentTopics = ({topics}: Props) => {
    const {t} = useTranslation()

    if (topics.length === 0) {
        return null;
    }

    return <div className="cc-content--tags">
        <div className="cc-tags__item">
            <div className="cc-tags__title">{t('topics')}</div>
            { topics.map((topic) => <div className="mc-button mc-button--rounded is-small" key={topic}>
                {topic}
            </div>)}
        </div>
    </div>
}

export default ContentTopics