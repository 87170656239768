import classnames from "classnames";
import IconGeneric from "./../Icon/IconGeneric";
import React, {useCallback, useState} from "react";
import {DateRange, DateRangeOptions} from "../../domain/DateRange";
import OptionList from "./OptionList";
import {useTranslation} from "react-i18next";
import {CustomDate} from "./CustomDate";
import useOutsideClick from "../../hooks/use-outside-click";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import useDates from "../../hooks/use-dates";

const DateSelector = () => {
    const {t} = useTranslation();
    const {dateRange, setDateRange} = useFiltersContext();
    const {getLastDayTime, getFirstDayTime} = useDates()
    const [selectedOption, setSelectedOption] = useState<DateRangeOptions>(dateRange.label);
    const [startDate, setStartDate] = useState<Date|null>(dateRange.start);
    const [endDate, setEndDate] = useState<Date|null>(dateRange.end);
    const [dateDropdown, setDateDropdown] = useState<boolean>(false);
    const [datePresetDropdown, setDatePresetDropdown] = useState<boolean>(true);
    const [dateIntervalDropdown, setDateIntervalDropdown] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => {
        setDateDropdown(false);
    }, [setDateDropdown]))

    const submitData = (dateRange: DateRange) => {
        setDateDropdown(false);
        setDateRange(dateRange);
    }

    return (
        <div className='cc-filter__container' ref={ref}>
            <div className={classnames('cc-filter__button cc-filter__button--date', {'is-open': dateDropdown})}
                onClick={() => { setDateDropdown(!dateDropdown) }}
            >
                <IconGeneric iconName="date-ico" className="mc-icon__generic--size-16" />
                {t(dateRange.label)}
            </div>
            { dateDropdown &&
                    <div className="cc-filter__dropdown has-fade-in">
                        <div className="cc-filter__tabs">
                            <span className={classnames('cc-filter__tabs-item', {'is-open': datePresetDropdown})}
                                onClick={() => {
                                    setDatePresetDropdown(true);
                                    setDateIntervalDropdown(false);
                                }}
                            >
                                {t('predefined')}
                            </span>
                            <span className={classnames('cc-filter__tabs-item', {'is-open': dateIntervalDropdown})}
                                onClick={() => {
                                    setDatePresetDropdown(false);
                                    setDateIntervalDropdown(true);
                                }}
                            >
                                {t('custom')}
                            </span>
                        </div>

                        { datePresetDropdown && <OptionList
                            onSelectOption={(option) => {
                                setSelectedOption(option);
                                setStartDate(null);
                                setEndDate(null);
                                submitData({
                                    label: option,
                                    start: null,
                                    end: null,
                                });
                            }}
                            selectedOption={selectedOption}
                        />}
                        { dateIntervalDropdown && <CustomDate
                            setSelectedOption={setSelectedOption}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />}

                        { dateIntervalDropdown &&
                            <>
                                <div className='mc-divider'></div>
                                <div className='oc-group oc-group--center'>
                                    <button className='mc-button mc-button--secondary mc-button--small' onClick={() => { setDateDropdown(false) }}>
                                        {t('cancel')}
                                    </button>
                                    <button className='mc-button mc-button--primary mc-button--small' onClick={() => {
                                        const end = endDate ?? (startDate ? getLastDayTime(startDate) : null);
                                        const start = startDate ? getFirstDayTime(startDate) : null;

                                        submitData({
                                            label: selectedOption,
                                            start,
                                            end
                                        })

                                        setEndDate(end);
                                    }}>
                                        {t('apply')}
                                    </button>
                                </div>
                            </>
                        }
                    </div>
            }
        </div>
    )
}

export default DateSelector