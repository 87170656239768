import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import useFiltersContext from "../../hooks/contexts/use-filters-context";
import useContentsContext from "../../hooks/contexts/use-contents-context";
import NavItem from "../Common/Buttons/NavItem";
import {ContentTypologies as DomainContentTypologies} from "../../domain/Content";
import IconGeneric from "../Icon/IconGeneric";

const ContentTypesFilters = () => {
    const {t} = useTranslation()
    const {filters: allFilters} = useContentsContext()
    const {
        filters,
        filtersList,
        add,
        remove,
        setTypology,
        removeGroup,
        typology
    } = useFiltersContext()

    const onClick = useCallback((contentType: DomainContentTypologies) => {
        setTypology(contentType)

        if (typology === contentType) {
            setTypology(null);
        }

        if (filters.contentType.length > 0) {
            removeGroup('contentType')
        }
    }, [filters.contentType, setTypology, removeGroup, typology])

    return (
        <div className="cc-content-nav__list">
            <div className="cc-chart-bar__title">{t('contents')}</div>
            <div className="cc-content-nav__items">
                { allFilters.contentType?.map((contentType) => {
                    const isActive = filtersList.includes(contentType);
                    return <NavItem key={contentType} isActive={filtersList.includes(contentType)} onClick={() => {
                        if (filtersList.includes(contentType)) {
                            remove('contentType', contentType)
                        } else {
                            add('contentType', contentType)
                            setTypology(null)
                        }
                    }}>
                        <div className="is-filter">
                            <span>{t(contentType)}</span>
                            {/* <IconGeneric iconName="filters-ico" className="mc-icon__generic--size-10" /> */}
                            <IconGeneric
                                iconName={isActive ? "filters-filled-primary-ico" : "filters-ico"}
                                className="mc-icon__generic--size-10"
                            />
                        </div>
                    </NavItem>
                }) }
                <NavItem isActive={typology === "not_found"} onClick={() => {
                    onClick("not_found");
                }}>
                    {t('404 pages')}
                </NavItem>
            </div>
        </div>
    )
}

export default ContentTypesFilters