import HeaderItem from "./HeaderItem";
import React, {useContext} from "react";
import {AppContext} from "../../pages/Home";
import DomainFavIcon from "../Common/DomainFavIcon";

interface Props {
    onClick: () => void
}

const HeaderAccounts = ({onClick}: Props) => {
    const { accounts, selectedAccount, setSelectedAccount } = useContext(AppContext);

    if (accounts.length === 1) {
        const account = accounts[0];

        return <HeaderItem
            key={account.id}
            className={"is-active"}
            onClick={onClick}
        >
            <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header">
                <DomainFavIcon url={account.url} alt={account.name} size={16} />
                {account.name}
            </button>
        </HeaderItem>
    }

    return <>
        { accounts.map((account) => {
            return <HeaderItem
                key={account.id}
                className={ (selectedAccount?.id === account.id ? "is-active" : "")}
                onClick={() => {
                    setSelectedAccount(account);
                    onClick();
                }}
            >
                <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header">
                    <DomainFavIcon url={account.url} alt={account.name} size={16} />
                    {account.name}
                </button>

            </HeaderItem>
        }) }
    </>
}

export default HeaderAccounts